import axios from 'src/utils/axios';
import helpers from 'src/helpers';
import { NOTIFICATION_STATUS } from 'src/constants';
import { addNotification } from 'src/actions/notificationActions';

export const GET_FORM_LINE_DEFINITION = '@formlinedefinitions/get-form-line-definition';
export const GET_FORM_LINE_DEFINITION_SUCCESS = '@formlinedefinitions/get-form-line-definition-success';
export const GET_FORM_LINE_DEFINITION_ERROR = '@formlinedefinitions/get-form-line-definition-error';

export const DELETE_FORM_LINE_DEFINITION = '@formlinedefinitions/del-form-line-definition';
export const DELETE_FORM_LINE_DEFINITION_SUCCESS = '@formlinedefinitions/del-form-line-definition-success';
export const DELETE_FORM_LINE_DEFINITION_ERROR = '@formlinedefinitions/del-form-line-definition-error';
export const CLEAR_FORM_LINE_DEFINITION_WAS_DELETED = '@formlinedefinitions/clear-form-line-definition-was-deleted';

export const UPDATE_FORM_LINE_DEFINITION = '@formlinedefinitions/update-form-line-definition';
export const UPDATE_FORM_LINE_DEFINITION_SUCCESS = '@formlinedefinitions/update-form-line-definition-success';
export const UPDATE_FORM_LINE_DEFINITION_ERROR = '@formlinedefinitions/update-form-line-definition-error';

export const CREATE_FORM_LINE_DEFINITION = '@formlinedefinitions/create-form-line-definition';
export const CREATE_FORM_LINE_DEFINITION_SUCCESS = '@formlinedefinitions/create-form-line-definition-success';
export const CREATE_FORM_LINE_DEFINITION_ERROR = '@formlinedefinitions/create-form-line-definition-error';

export const SET_FORM_LINE_DEFINITION_DETAILS_TAB = '@formlinedefinitions/set-form-line-definition-details-tab';

export const SET_GO_TO_FORM_LINE_DEFINITION_DETAILS = '@formlinedefinitions/set-go-to-form-line-definition-details';
export const CLEAR_GO_TO_FORM_LINE_DEFINITION_DETAILS = '@formlinedefinitions/clear-go-to-form-line-definition-details';

export const SET_FORM_FORM_LINE_DEFINITION = '@formlinedefinitions/set-form-form-line-definition';

export const SET_VALUES_FORM_LINE_IMAGE = '@formlinedefinitions/set-values-form-line-image';

export const SET_FORM_LINE_DEFINITION_ID = '@formlinedefinitions/set-form-line-definition-group-id';

export const SET_UPDATE_PARTY_FORM_LINE = '@formlinedefinitions/set-update-party-form-line';

export const getFormLineDefinition = (formLineDefinitionId) => async (dispatch) => {
  dispatch({
    type: GET_FORM_LINE_DEFINITION,
  });

  try {
    const response = await axios.get(`/api/formLineDefinitions/${formLineDefinitionId}`);
    dispatch({
      type: GET_FORM_LINE_DEFINITION_SUCCESS,
      payload: response?.data || {}
    });
  } catch (e) {
    const {
      error,
      message,
    } = e?.response?.data || {};
    dispatch(addNotification(GET_FORM_LINE_DEFINITION_ERROR, helpers.transform.toString(message || error || 'Server error'), NOTIFICATION_STATUS.ERROR));
    dispatch({
      type: GET_FORM_LINE_DEFINITION_ERROR,
    });
  }
};

export const deleteFormLineDefinition = (formLineDefinitionId) => async (dispatch, getState) => {
  try {
    dispatch({
      type: DELETE_FORM_LINE_DEFINITION,
    });
    const {
      updatePartyFormLine,
    } = getState()?.formDefinitionDetails || {};

    const response = await axios.post(`/api/FormDefinition/${formLineDefinitionId}`, {
      updatePartyFormLine,
    });
    const {
      success,
      error,
    } = response?.data || {};

    dispatch({
      type: DELETE_FORM_LINE_DEFINITION_SUCCESS,
      success,
    });

    if (error) {
      dispatch(addNotification(DELETE_FORM_LINE_DEFINITION, error, NOTIFICATION_STATUS.ERROR));
      return;
    }

    if (!success) {
      dispatch(getFormLineDefinition(formLineDefinitionId));
      dispatch(addNotification(DELETE_FORM_LINE_DEFINITION_ERROR, 'Can not delete the action', NOTIFICATION_STATUS.ERROR));
      return;
    }
    dispatch(addNotification(DELETE_FORM_LINE_DEFINITION_SUCCESS, 'Form Line Definition was deleted', NOTIFICATION_STATUS.SUCCESS));
  } catch (e) {
    const {
      error,
    } = e?.response?.data || {};
    const {
      name: errorName,
    } = error || {};

    dispatch(addNotification(DELETE_FORM_LINE_DEFINITION_ERROR, helpers.transform.toString(errorName || 'Server error'), NOTIFICATION_STATUS.ERROR));
    dispatch(getFormLineDefinition(formLineDefinitionId));
    dispatch({
      type: DELETE_FORM_LINE_DEFINITION_ERROR,
    });
  }
};

export const clearFormLineDefinitionWasDeleted = () => async (dispatch) => {
  dispatch({
    type: CLEAR_FORM_LINE_DEFINITION_WAS_DELETED,
  });
};

export const updateFormLineDefinition = (formLineDefinitionChanges, doWhenTry = false, doWhenCatch = false) => async (dispatch, getState) => {
  try {
    dispatch({
      type: UPDATE_FORM_LINE_DEFINITION,
    });
    const {
      updatePartyFormLine,
    } = getState()?.formDefinitionDetails || {};

    const response = await axios.put('/api/formDefinition', {
      formLineDefinitionChanges,
      updatePartyFormLine,
    });
    const {
      success,
    } = response?.data || {};

    if (!success) {
      dispatch(addNotification(UPDATE_FORM_LINE_DEFINITION_ERROR, 'Can not update form line definition', NOTIFICATION_STATUS.ERROR));
      dispatch({
        type: UPDATE_FORM_LINE_DEFINITION_ERROR,
      });
      return;
    }

    dispatch(addNotification(UPDATE_FORM_LINE_DEFINITION_SUCCESS, 'The form line definition has been successfully updated', NOTIFICATION_STATUS.SUCCESS));
    dispatch({
      type: UPDATE_FORM_LINE_DEFINITION_SUCCESS,
    });

    if (doWhenTry) {
      doWhenTry();
    }
  } catch (e) {
    const {
      error,
      message,
    } = e?.response?.data || {};

    const { message: errorMessage } = error || {};
    dispatch(addNotification(UPDATE_FORM_LINE_DEFINITION_ERROR, message || errorMessage || 'Form Line Definition didn\'t update', NOTIFICATION_STATUS.ERROR));
    dispatch({
      type: UPDATE_FORM_LINE_DEFINITION_ERROR,
    });

    if (doWhenCatch) {
      doWhenCatch(e);
    }
  }
};

export const createFormLineDefinition = (formLineDefinitionChanges, doWhenTry = false, doWhenCatch = false) => async (dispatch, getState) => {
  try {
    dispatch({
      type: CREATE_FORM_LINE_DEFINITION,
    });
    const {
      updatePartyFormLine,
    } = getState()?.formDefinitionDetails || {};
    const response = await axios.post('/api/formDefinition/create', {
      formLineDefinitionChanges,
      updatePartyFormLine,
    });
    const {
      id,
    } = response?.data || {};

    if (!id) {
      dispatch(addNotification(CREATE_FORM_LINE_DEFINITION_ERROR, 'Can not create the form line definition', NOTIFICATION_STATUS.ERROR));
      dispatch({
        type: CREATE_FORM_LINE_DEFINITION_ERROR,
      });
      return;
    }

    dispatch(addNotification(CREATE_FORM_LINE_DEFINITION_SUCCESS, 'Form line successfully added to the BOTTOM', NOTIFICATION_STATUS.SUCCESS));

    dispatch({
      type: CREATE_FORM_LINE_DEFINITION_SUCCESS,
    });

    if (doWhenTry) {
      doWhenTry(id);
    }
  } catch (e) {
    const {
      message,
      error,
    } = e?.response?.data || {};
    const { message: errorMessage } = error || {};
    dispatch(addNotification(CREATE_FORM_LINE_DEFINITION_ERROR, message || errorMessage || 'Try again later', NOTIFICATION_STATUS.ERROR));
    dispatch({
      type: CREATE_FORM_LINE_DEFINITION_ERROR,
    });

    if (doWhenCatch) {
      doWhenCatch(e);
    }
  }
};

export const setFormFormLineDefinitionDetailsTab = (tabName) => async (dispatch) => {
  dispatch({
    type: SET_FORM_LINE_DEFINITION_DETAILS_TAB,
    payload: tabName
  });
};

export const setFormFormLineDefinition = (payload) => ({
  type: SET_FORM_FORM_LINE_DEFINITION,
  payload
});

export const setValuesFormLineImage = (values) => ({
  type: SET_VALUES_FORM_LINE_IMAGE,
  payload: values
});

export const setFormLineDefinitionId = () => ({
  type: SET_FORM_LINE_DEFINITION_ID,
});

export const setUpdatePartyFormLine = (payload) => async (dispatch) => {
  dispatch({
    type: SET_UPDATE_PARTY_FORM_LINE,
    payload,
  });
};
