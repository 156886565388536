import axios from 'src/utils/axios';
import helpers from 'src/helpers';
import { NOTIFICATION_STATUS } from 'src/constants';
import { addNotification } from 'src/actions/notificationActions';

export const GET_FORM_LINE = '@formlines/get-form-line';
export const GET_FORM_LINE_SUCCESS = '@formlines/get-form-line-success';
export const GET_FORM_LINE_ERROR = '@formlines/get-form-line-error';

export const DELETE_FORM_LINE = '@formlines/del-form-line';
export const DELETE_FORM_LINE_SUCCESS = '@formlines/del-form-line-success';
export const DELETE_FORM_LINE_ERROR = '@formlines/del-form-line-error';
export const CLEAR_FORM_LINE_WAS_DELETED = '@formlines/clear-form-line-was-deleted';

export const UPDATE_FORM_LINE = '@formlines/update-form-line';
export const UPDATE_FORM_LINE_SUCCESS = '@formlines/update-form-line-success';
export const UPDATE_FORM_LINE_ERROR = '@formlines/update-form-line-error';

export const CREATE_FORM_LINE = '@formlines/create-form-line';
export const CREATE_FORM_LINE_SUCCESS = '@formlines/create-form-line-success';
export const CREATE_FORM_LINE_ERROR = '@formlines/create-form-line-error';

export const SET_FORM_LINE_DETAILS_TAB = '@formlines/set-form-line-details-tab';

export const SET_GO_TO_FORM_LINE_DETAILS = '@formlines/set-go-to-form-line-details';
export const CLEAR_GO_TO_FORM_LINE_DETAILS = '@formlines/clear-go-to-form-line-details';

export const SET_FORM_FORM_LINE = '@formlines/set-form-form-line';

export const SET_VALUES_FORM_LINE_IMAGE = '@formlines/set-values-form-line-image';

export const SET_FORM_LINE_ID = '@formlines/set-form-line-group-id';

export const SET_UPDATE_PARTY_FORM_LINE = '@formlines/set-update-party-form-line';

export const getFormLine = (formLineId) => async (dispatch) => {
  dispatch({
    type: GET_FORM_LINE,
  });

  try {
    const response = await axios.get(`/api/formPartyJoin/${formLineId}`);
    dispatch({
      type: GET_FORM_LINE_SUCCESS,
      payload: response?.data || {}
    });
  } catch (e) {
    const {
      error,
      message,
    } = e?.response?.data || {};
    dispatch(addNotification(GET_FORM_LINE_ERROR, helpers.transform.toString(message || error || 'Server error'), NOTIFICATION_STATUS.ERROR));
    dispatch({
      type: GET_FORM_LINE_ERROR,
    });
  }
};

export const deleteFormLine = (formLineId) => async (dispatch) => {
  try {
    dispatch({
      type: DELETE_FORM_LINE,
    });

    const response = await axios.post(`/api/FormPartyJoin/${formLineId}`);
    const {
      success,
      error,
    } = response?.data || {};

    dispatch({
      type: DELETE_FORM_LINE_SUCCESS,
      success,
    });

    if (error) {
      dispatch(addNotification(DELETE_FORM_LINE, error, NOTIFICATION_STATUS.ERROR));
      return;
    }

    if (!success) {
      dispatch(getFormLine(formLineId));
      dispatch(addNotification(DELETE_FORM_LINE_ERROR, 'Can not delete the action', NOTIFICATION_STATUS.ERROR));
      return;
    }
    dispatch(addNotification(DELETE_FORM_LINE_SUCCESS, 'Form Line was deleted', NOTIFICATION_STATUS.SUCCESS));
  } catch (e) {
    const {
      error,
    } = e?.response?.data || {};
    const {
      name: errorName,
    } = error || {};

    dispatch(addNotification(DELETE_FORM_LINE_ERROR, helpers.transform.toString(errorName || 'Server error'), NOTIFICATION_STATUS.ERROR));
    dispatch(getFormLine(formLineId));
    dispatch({
      type: DELETE_FORM_LINE_ERROR,
    });
  }
};

export const clearFormLineWasDeleted = () => async (dispatch) => {
  dispatch({
    type: CLEAR_FORM_LINE_WAS_DELETED,
  });
};

export const updateFormLine = (formLineChanges, doWhenTry = false, doWhenCatch = false) => async (dispatch) => {
  try {
    dispatch({
      type: UPDATE_FORM_LINE,
    });

    const response = await axios.put('/api/formPartyJoin', {
      formLineChanges,
    });
    const {
      success,
    } = response?.data || {};

    if (!success) {
      dispatch(addNotification(UPDATE_FORM_LINE_ERROR, 'Can not update form line', NOTIFICATION_STATUS.ERROR));
      dispatch({
        type: UPDATE_FORM_LINE_ERROR,
      });
      return;
    }

    dispatch(addNotification(UPDATE_FORM_LINE_SUCCESS, 'The form line has been successfully updated', NOTIFICATION_STATUS.SUCCESS));
    dispatch({
      type: UPDATE_FORM_LINE_SUCCESS,
    });

    if (doWhenTry) {
      doWhenTry();
    }
  } catch (e) {
    const {
      error,
      message,
    } = e?.response?.data || {};

    const { message: errorMessage } = error || {};
    dispatch(addNotification(UPDATE_FORM_LINE_ERROR, message || errorMessage || 'Form Line didn\'t update', NOTIFICATION_STATUS.ERROR));
    dispatch({
      type: UPDATE_FORM_LINE_ERROR,
    });

    if (doWhenCatch) {
      doWhenCatch(e);
    }
  }
};

export const createFormLine = (formLineChanges, doWhenTry = false, doWhenCatch = false) => async (dispatch) => {
  try {
    dispatch({
      type: CREATE_FORM_LINE,
    });
    const response = await axios.post('/api/formPartyJoin/create', {
      formLineChanges,
    });
    const {
      id,
    } = response?.data || {};

    if (!id) {
      dispatch(addNotification(CREATE_FORM_LINE_ERROR, 'Can not create the form line', NOTIFICATION_STATUS.ERROR));
      dispatch({
        type: CREATE_FORM_LINE_ERROR,
      });
      return;
    }

    dispatch(addNotification(CREATE_FORM_LINE_SUCCESS, 'Form line successfully added to the BOTTOM', NOTIFICATION_STATUS.SUCCESS));

    dispatch({
      type: CREATE_FORM_LINE_SUCCESS,
    });

    if (doWhenTry) {
      doWhenTry(id);
    }
  } catch (e) {
    const {
      message,
      error,
    } = e?.response?.data || {};
    const { message: errorMessage } = error || {};
    dispatch(addNotification(CREATE_FORM_LINE_ERROR, message || errorMessage || 'Try again later', NOTIFICATION_STATUS.ERROR));
    dispatch({
      type: CREATE_FORM_LINE_ERROR,
    });

    if (doWhenCatch) {
      doWhenCatch(e);
    }
  }
};

export const setFormFormLineDetailsTab = (tabName) => async (dispatch) => {
  dispatch({
    type: SET_FORM_LINE_DETAILS_TAB,
    payload: tabName
  });
};

export const setFormFormLine = (payload) => ({
  type: SET_FORM_FORM_LINE,
  payload
});

export const setValuesFormLineImage = (values) => ({
  type: SET_VALUES_FORM_LINE_IMAGE,
  payload: values
});

export const setFormLineId = () => ({
  type: SET_FORM_LINE_ID,
});
