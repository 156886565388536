/* eslint-disable no-param-reassign */
import produce from 'immer';
import {
  SET_CURRENT_ORDER,
  SET_ORDER_TO_PROCESSED,
  SET_IS_MODAL_VISIBLE,
  SET_SELECTED_ORDERS,
  GET_ORDERS,
  GET_ORDERS_SUCCESS,
  GET_ORDERS_ERROR,
  SET_QUERY,
  SET_ORDERS_FILTER,
  UPDATE_ORDER,
  PROCESS_ORDERS,
  PROCESS_ORDERS_ERROR,
  UPDATE_AND_PROCESS_ORDER,
  SET_IS_MODAL_LOADING,
  SET_MODAL_MESSAGE,
  CLEAR_ORDERS_AND_QUERY,
  SET_VALUES_FORM_EDIT_ORDER,
  GET_FOLLOW_UP_MESSAGES,
  GET_FOLLOW_UP_MESSAGES_SUCCESS,
  GET_FOLLOW_UP_MESSAGES_ERROR,
  REPLACE_SYSTEM_GEN_MESSAGE,
  REPLACE_SYSTEM_GEN_MESSAGE_CUSTOMIZE_ORDERS,
  REPLACE_SYSTEM_GEN_MESSAGE_CUSTOMIZE_ORDERS_SUCCESS,
  REPLACE_SYSTEM_GEN_MESSAGE_CUSTOMIZE_ORDERS_ERROR,
  SET_MODAL_FORM_CUSTOMIZE_ORDERS_VISIBLE,
  SET_MODAL_FORM_CUSTOMIZE_ORDERS_LOADING,
  SET_MODAL_FORM_CUSTOMIZE_ORDERS_FORM_DATA,
  GET_PARTIES_LIST,
  GET_PARTIES_LIST_SUCCESS,
  GET_PARTIES_LIST_ERROR,
  CLEAR_ORDERS_QUERY_FILTER,
  GET_PURCHASED_FROM_HOST_OPTIONS,
  GET_PURCHASED_FROM_HOST_OPTIONS_SUCCESS,
  GET_PURCHASED_FROM_HOST_OPTIONS_ERROR,
  GET_PURCHASED_FROM_PARTY_OPTIONS,
  GET_PURCHASED_FROM_PARTY_OPTIONS_SUCCESS,
  GET_PURCHASED_FROM_PARTY_OPTIONS_ERROR,
  GET_PURCHASED_FROM_PARTY_NAME_OPTIONS,
  GET_PURCHASED_FROM_PARTY_NAME_OPTIONS_SUCCESS,
  GET_PURCHASED_FROM_PARTY_NAME_OPTIONS_ERROR,
  GET_PURCHASED_PRODUCT_OPTIONS,
  GET_PURCHASED_PRODUCT_OPTIONS_SUCCESS,
  GET_PURCHASED_PRODUCT_OPTIONS_ERROR,
  SET_BACK_TO_ORDER_LIST,
} from 'src/actions/ordersActions';

const modalMessage = {
  isVisible: false,
  waituntil: '',
};

const modalFormCustomizeOrders = {
  isVisible: false,
  loading: false,
  formData: {
    attachment: false,
    systemGenMessageReplaced: false,
  },
};

const initialFilters = {
  // phones: null,
  newExistingCustomer: null,
  purchasedFromHost: null,
  purchasedFromParty: null,
  purchasedFromPartyName: null,
  purchasedProductList: [],
  orderDateFrom: null,
  orderDateTo: null,
};

const formEditOrder = {
  customAttachment: false,
  customAttachmentContainer: null,
  systemGenMessageReplaced: false,
};

const followUpMessages = {
  followUpMessageList: [],
  loading: false,
};

const backToOrderList = {
  orders: [],
  query: {},
  active: false,
  comeBack: false,
};

const initialState = {
  orders: [],
  selectedOrders: [],
  query: {
    search: '',
    // subset: 'New',
    status: 'New',
    filters: initialFilters,
    sort: [['orderDateTime', 'asc']],
    // sort: [],
    page: 1,
    pageSize: 50,
    count: 0
  },
  purchasedFromHostOptions: {
    data: [],
    loading: false,
  },
  purchasedFromPartyOptions: {
    data: [],
    loading: false,
  },
  purchasedFromPartyNameOptions: {
    data: [],
    loading: false,
  },
  purchasedProductOptions: {
    data: [],
    loading: false,
  },
  currentOrder: {},
  processedOrder: 0,
  parties: [],
  modalMessage,
  formEditOrder,
  modalFormCustomizeOrders,
  followUpMessages,
  backToOrderList,
  isModalVisible: false,
  isModalLoading: false,
  loading: false,
};

const ordersReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case SET_CURRENT_ORDER: {
      return produce(state, (draft) => {
        draft.currentOrder = payload;
      });
    }

    case SET_ORDER_TO_PROCESSED: {
      return produce(state, (draft) => {
        draft.processedOrder = payload;
      });
    }

    case SET_SELECTED_ORDERS: {
      return produce(state, (draft) => {
        draft.selectedOrders = payload || initialState.selectedOrders;
      });
    }

    case SET_IS_MODAL_VISIBLE: {
      return produce(state, (draft) => {
        draft.isModalVisible = !state.isModalVisible;
      });
    }

    case SET_MODAL_MESSAGE: {
      return produce(state, (draft) => {
        draft.modalMessage = {
          ...state.modalMessage,
          ...payload,
        };
      });
    }

    case GET_ORDERS: {
      return produce(state, (draft) => {
        draft.loading = true;
      });
    }

    case GET_ORDERS_SUCCESS: {
      return produce(state, (draft) => {
        draft.orders = payload?.orders || [];
        draft.query.count = payload?.count || 0;
        draft.loading = false;
        draft.selectedOrders = initialState.selectedOrders;
      });
    }

    case GET_ORDERS_ERROR: {
      return produce(state, (draft) => {
        draft.orders = initialState.orders;
        draft.selectedOrders = initialState.selectedOrders;
        draft.loading = false;
      });
    }

    // case SET_QUERY: {
    //   return produce(state, (draft) => {
    //     draft.query = {
    //       ...state.query,
    //       ...payload
    //     };
    //   });
    // }
    case SET_QUERY: {
      return produce(state, (draft) => {
        draft.query = payload ? {
          ...state.query,
          ...payload
        } : initialState.query;
      });
    }

    case SET_ORDERS_FILTER: {
      return produce(state, (draft) => {
        draft.query.filters = payload ? {
          ...state.query.filters,
          ...payload,
        } : initialFilters;
      });
    }

    case UPDATE_ORDER: {
      return produce(state, (draft) => {
        draft.currentOrder = payload || {};
        draft.isModalLoading = initialState.isModalLoading;
      });
    }

    case PROCESS_ORDERS: {
      return produce(state, (draft) => {
        draft.processedOrder = initialState.processedOrder;
      });
    }

    case PROCESS_ORDERS_ERROR: {
      return produce(state, (draft) => {
        draft.processedOrder = initialState.processedOrder;
        draft.isModalLoading = initialState.isModalLoading;
      });
    }

    case UPDATE_AND_PROCESS_ORDER: {
      return produce(state, (draft) => {
        draft.isModalVisible = state.processedOrder ? initialState.isModalVisible : state.isModalVisible;
        draft.processedOrder = initialState.processedOrder;
        draft.isModalLoading = initialState.isModalLoading;
      });
    }

    case SET_IS_MODAL_LOADING: {
      return produce(state, (draft) => {
        draft.isModalLoading = payload;
      });
    }

    case CLEAR_ORDERS_AND_QUERY: {
      return produce(state, (draft) => {
        draft.orders = initialState.orders;
        draft.query = initialState.query;
      });
    }

    case SET_VALUES_FORM_EDIT_ORDER: {
      return produce(state, (draft) => {
        draft.formEditOrder = payload ? {
          ...state.formEditOrder,
          ...payload,
        } : formEditOrder;
      });
    }

    case GET_FOLLOW_UP_MESSAGES: {
      return produce(state, (draft) => {
        draft.followUpMessages.loading = true;
      });
    }

    case GET_FOLLOW_UP_MESSAGES_SUCCESS:
    case GET_FOLLOW_UP_MESSAGES_ERROR: {
      return produce(state, (draft) => {
        draft.followUpMessages.loading = false;
        draft.followUpMessages.followUpMessageList = payload ? [
          ...state.followUpMessages.followUpMessageList,
          ...payload,
        ] : followUpMessages.followUpMessageList;
      });
    }

    case REPLACE_SYSTEM_GEN_MESSAGE: {
      return produce(state, (draft) => {
        draft.isModalLoading = false;
        draft.formEditOrder.systemGenMessageReplaced = payload || false;
      });
    }

    case REPLACE_SYSTEM_GEN_MESSAGE_CUSTOMIZE_ORDERS: {
      return produce(state, (draft) => {
        draft.modalFormCustomizeOrders.loading = true;
      });
    }

    case REPLACE_SYSTEM_GEN_MESSAGE_CUSTOMIZE_ORDERS_SUCCESS: {
      return produce(state, (draft) => {
        draft.modalFormCustomizeOrders.loading = false;
        draft.modalFormCustomizeOrders.formData.systemGenMessageReplaced = payload || false;
      });
    }

    case REPLACE_SYSTEM_GEN_MESSAGE_CUSTOMIZE_ORDERS_ERROR: {
      return produce(state, (draft) => {
        draft.modalFormCustomizeOrders.loading = false;
        draft.modalFormCustomizeOrders.formData.systemGenMessageReplaced = payload || false;
      });
    }

    case SET_MODAL_FORM_CUSTOMIZE_ORDERS_VISIBLE: {
      return produce(state, (draft) => {
        draft.modalFormCustomizeOrders.isVisible = payload;
      });
    }

    case SET_MODAL_FORM_CUSTOMIZE_ORDERS_LOADING: {
      return produce(state, (draft) => {
        draft.modalFormCustomizeOrders.loading = payload;
      });
    }

    case SET_MODAL_FORM_CUSTOMIZE_ORDERS_FORM_DATA: {
      return produce(state, (draft) => {
        draft.modalFormCustomizeOrders.formData = payload ? {
          ...state.modalFormCustomizeOrders.formData,
          ...payload,
        } : modalFormCustomizeOrders.formData;
      });
    }

    case GET_PARTIES_LIST: {
      return produce(state, (draft) => {
        draft.loading = true;
      });
    }

    case GET_PARTIES_LIST_SUCCESS: {
      return produce(state, (draft) => {
        draft.parties = payload || {};
        draft.loading = false;
      });
    }

    case GET_PARTIES_LIST_ERROR: {
      return produce(state, (draft) => {
        draft.loading = false;
      });
    }

    case CLEAR_ORDERS_QUERY_FILTER: {
      return produce(state, (draft) => {
        draft.query.filters = initialFilters;
        draft.query.page = initialState.query.page;
      });
    }

    case GET_PURCHASED_FROM_HOST_OPTIONS: {
      return produce(state, (draft) => {
        draft.purchasedFromHostOptions.loading = true;
        draft.purchasedFromHostOptions.data = [];
      });
    }

    case GET_PURCHASED_FROM_HOST_OPTIONS_SUCCESS: {
      return produce(state, (draft) => {
        draft.purchasedFromHostOptions.loading = false;
        draft.purchasedFromHostOptions.data = payload || [];
      });
    }

    case GET_PURCHASED_FROM_HOST_OPTIONS_ERROR: {
      return produce(state, (draft) => {
        draft.purchasedFromHostOptions.loading = false;
      });
    }

    case GET_PURCHASED_FROM_PARTY_OPTIONS: {
      return produce(state, (draft) => {
        draft.purchasedFromPartyOptions.loading = true;
        draft.purchasedFromPartyOptions.data = [];
      });
    }

    case GET_PURCHASED_FROM_PARTY_OPTIONS_SUCCESS: {
      return produce(state, (draft) => {
        draft.purchasedFromPartyOptions.loading = false;
        draft.purchasedFromPartyOptions.data = payload || [];
      });
    }

    case GET_PURCHASED_FROM_PARTY_OPTIONS_ERROR: {
      return produce(state, (draft) => {
        draft.purchasedFromPartyOptions.loading = false;
      });
    }

    case GET_PURCHASED_FROM_PARTY_NAME_OPTIONS: {
      return produce(state, (draft) => {
        draft.purchasedFromPartyNameOptions.loading = true;
        draft.purchasedFromPartyNameOptions.data = [];
      });
    }

    case GET_PURCHASED_FROM_PARTY_NAME_OPTIONS_SUCCESS: {
      return produce(state, (draft) => {
        draft.purchasedFromPartyNameOptions.loading = false;
        draft.purchasedFromPartyNameOptions.data = payload || [];
      });
    }

    case GET_PURCHASED_FROM_PARTY_NAME_OPTIONS_ERROR: {
      return produce(state, (draft) => {
        draft.purchasedFromPartyNameOptions.loading = false;
      });
    }

    case GET_PURCHASED_PRODUCT_OPTIONS: {
      return produce(state, (draft) => {
        draft.purchasedProductOptions.loading = true;
        draft.purchasedProductOptions.data = [];
      });
    }

    case GET_PURCHASED_PRODUCT_OPTIONS_SUCCESS: {
      return produce(state, (draft) => {
        draft.purchasedProductOptions.loading = false;
        draft.purchasedProductOptions.data = payload || [];
      });
    }

    case GET_PURCHASED_PRODUCT_OPTIONS_ERROR: {
      return produce(state, (draft) => {
        draft.purchasedProductOptions.loading = false;
      });
    }

    case SET_BACK_TO_ORDER_LIST: {
      return produce(state, (draft) => {
        draft.backToOrderList = payload ? {
          ...state.backToOrderList,
          ...payload,
        } : backToOrderList;
      });
    }

    default: {
      return state;
    }
  }
};

export default ordersReducer;
