import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from 'tss-react/mui';
import { useDispatch } from 'react-redux';
import { useSnackbar } from 'notistack';
import { useHistory } from 'react-router';
import { logout } from 'src/actions/accountActions';
import NavBar from 'src/layouts/DashboardLayout/NavBar';
import TopBar from 'src/layouts/DashboardLayout/TopBar';
import RedirectLogic from 'src/RedirectLogic';
import Notification from 'src/containers/Notification';
import TOSAndPrivacyStatement from 'src/containers/TOSAndPrivacyStatement';
import AdminSignInAs from 'src/containers/AdminSignInAs';
import ChangeCompanyTitle from 'src/containers/ChangeCompanyTitle';
import ChangeCampaigns from 'src/containers/ChangeCampaigns';
import DashboardLayoutTopMessage from 'src/layouts/DashboardLayout/DashboardLayoutTopMessage';

import dashboardLayoutStyle from 'src/layouts/DashboardLayout/dashboardLayout.style';

function DashboardLayout({ children, classes }) {
  const history = useHistory();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const [isMobileNavOpen, setMobileNavOpen] = useState(false);
  const [isDesktopNavOpen, setDesktopNavOpen] = useState(false);
  const wrapperDesktopNavClass = !isDesktopNavOpen ? ' wrapperWithSmallNabBar' : '';

  const setLogout = async (toDoSomethingInTry = null) => {
    try {
      await dispatch(logout());
      history.push('/');
      if (toDoSomethingInTry) {
        toDoSomethingInTry();
      }
    } catch (error) {
      enqueueSnackbar('Unable to logout', {
        variant: 'error'
      });
    }
  };

  return (
    <div className={classes.root}>
      <TopBar
        onMobileNavOpen={() => setMobileNavOpen(true)}
        logout={setLogout}
      />
      <NavBar
        onMobileClose={() => setMobileNavOpen(false)}
        openMobile={isMobileNavOpen}
        isDesktopNavOpen={isDesktopNavOpen}
        setDesktopNavOpen={setDesktopNavOpen}
        logout={setLogout}
      />
      <div className={`${classes.wrapper}${wrapperDesktopNavClass}`}>
        <div className={classes.contentContainer}>
          <div className={classes.content}>
            <DashboardLayoutTopMessage />
            {children}
          </div>
        </div>
      </div>
      <RedirectLogic />
      <AdminSignInAs logout={setLogout} />
      <ChangeCompanyTitle logout={setLogout} />
      <ChangeCampaigns classes={classes} logout={setLogout} />
      <TOSAndPrivacyStatement logout={setLogout} />
      <Notification />
    </div>
  );
}

DashboardLayout.propTypes = {
  classes: PropTypes.object.isRequired,
  children: PropTypes.any
};

export default withStyles(DashboardLayout, dashboardLayoutStyle, { withTheme: true });
