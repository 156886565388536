import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import ordersReducer from 'src/reducers/ordersReducer';
import followUpReducer from 'src/reducers/followUpReducer';
import notificationReducer from 'src/reducers/notificationReducer';
import accountReducer from 'src/reducers/accountReducer';
import accountSettingsReducer from 'src/reducers/accountSettingsReducer';
import notificationsReducer from 'src/reducers/notificationsReducer';
import chatReducer from 'src/reducers/chatReducer';
import mailReducer from 'src/reducers/mailReducer';
import kanbanReducer from 'src/reducers/kanbanReducer';
import messagesReducer from 'src/reducers/messagesReducer';
import subscriptionsReducer from 'src/reducers/subscriptionsReducer';
import customersReducer from 'src/reducers/customersReducer';
import customerDetailsReducer from 'src/reducers/customerDetailsReducer';
import partiesReducer from 'src/reducers/partiesReducer';
import partyDetailsReducer from 'src/reducers/partyDetailsReducer';
import partySettingsReducer from 'src/reducers/partySettingsReducer';
import partyVenuesReducer from 'src/reducers/partyVenuesReducer';
import partyVenueDetailsReducer from 'src/reducers/partyVenueDetailsReducer';
import partyTypesReducer from 'src/reducers/partyTypesReducer';
import partyTypeDetailsReducer from 'src/reducers/partyTypeDetailsReducer';
import orderSettingsReducer from 'src/reducers/orderSettingsReducer';
import orderFollowUpDetailsReducer from 'src/reducers/orderFollowUpDetailsReducer';
import orderDetailsReducer from 'src/reducers/orderDetailsReducer';
import smartActionGroupsReducer from 'src/reducers/smartActionGroupsReducer';
import smartActionsReducer from 'src/reducers/smartActionsReducer';
import smartActionDetailsReducer from 'src/reducers/smartActionDetailsReducer';
// import scheduledSmartActionGroupsReducer from 'src/reducers/scheduledSmartActionGroupsReducer';
import scheduledSmartActionsReducer from 'src/reducers/scheduledSmartActionsReducer';
import scheduledSmartActionDetailsReducer from 'src/reducers/scheduledSmartActionDetailsReducer';
import formsReducer from 'src/reducers/formsReducer';
import formDefinitionReducer from 'src/reducers/formDefinitionReducer';
import formDefinitionDetailsReducer from 'src/reducers/formDefinitionDetailsReducer';
import formPartyJoinReducer from 'src/reducers/formPartyJoinReducer';
import formPartyJoinDetailsReducer from 'src/reducers/formPartyJoinDetailsReducer';
import signupReducer from 'src/reducers/signupReducer';

const rootReducer = combineReducers({
  account: accountReducer,
  accountSettings: accountSettingsReducer,
  partySettings: partySettingsReducer,
  orderSettings: orderSettingsReducer,
  orderFollowUpDetails: orderFollowUpDetailsReducer,
  notifications: notificationsReducer,
  notification: notificationReducer,
  chat: chatReducer,
  mail: mailReducer,
  kanban: kanbanReducer,
  form: formReducer,
  orders: ordersReducer,
  orderDetails: orderDetailsReducer,
  followUp: followUpReducer,
  messages: messagesReducer,
  subscriptions: subscriptionsReducer,
  customers: customersReducer,
  customerDetails: customerDetailsReducer,
  parties: partiesReducer,
  partyDetails: partyDetailsReducer,
  partyVenues: partyVenuesReducer,
  partyVenueDetails: partyVenueDetailsReducer,
  partyTypes: partyTypesReducer,
  partyTypeDetails: partyTypeDetailsReducer,
  smartActionGroups: smartActionGroupsReducer,
  smartActions: smartActionsReducer,
  smartActionDetails: smartActionDetailsReducer,
  // scheduledSmartActionGroups: scheduledSmartActionGroupsReducer,
  scheduledSmartActions: scheduledSmartActionsReducer,
  scheduledSmartActionDetails: scheduledSmartActionDetailsReducer,
  forms: formsReducer,
  formDefinition: formDefinitionReducer,
  formDefinitionDetails: formDefinitionDetailsReducer,
  formPartyJoin: formPartyJoinReducer,
  formPartyJoinDetails: formPartyJoinDetailsReducer,
  signup: signupReducer,
});

export default rootReducer;
