// import React from 'react';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  SvgIcon,
} from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import OndemandVideoIcon from '@mui/icons-material/OndemandVideo';
import PopConfirm from 'src/components/PopConfirm';
import useMediaQuery from '@mui/material/useMediaQuery';
import Tooltip from 'src/components/Tooltip';
import helpers from 'src/helpers';
import ModalDialogVideo from 'src/components/ModalDialogVideo/index';

import { useTheme } from '@mui/material/styles';
import { withStyles } from 'tss-react/mui';
import modalDialogStyles from 'src/components/ModalDialog/modalDialog.style';

function ModalDialog({
  onClose,
  onOk,
  okButtonProps,
  onClickThirdButton,
  thirdButtonProps,
  isVisible,
  content,
  loading,
  title,
  titleClassOptions,
  footer,
  largeModal,
  closeText,
  okText,
  thirdButtonText,
  closeOnClickOnlyTheButton,
  classes,
  isHeaderVisible,
  veryBlackBackground,
  hideCloseButton,
  className,
  okClassName,
  closeClassName,
  thirdButtonClassName,
  reverseFooterButtons,
  popConfirmProps,
  isCloseIconHid,
  showVideo,
  videoTooltip,
  videoURL,
}) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const classesForDialog = {
    root: classes.root,
    ...(largeModal ? { paper: classes.largeDialogPaper } : {}),
    ...(veryBlackBackground ? { container: classes.dialogContainerBlack } : {}),
  };

  const getTitle = () => isHeaderVisible && (
    <DialogTitle>
      <div className={`${classes.title} ${titleClassOptions}`}>
        <div className={`${classes.titleInner} ${isCloseIconHid ? 'pl0' : ''}`}>{title}</div>
        {showVideo
          && (
            <Tooltip
              title={videoTooltip}
              placement="left-start"
            >
              <IconButton color="secondary" onClick={handleOpen} size="large">
                <SvgIcon fontSize="large">
                  <OndemandVideoIcon />
                </SvgIcon>
              </IconButton>
            </Tooltip>
          )}
        {!isCloseIconHid && (
          <IconButton
            className={classes.close}
            aria-label="close"
            onClick={onClose}
            size="large"
          >
            <CloseIcon />
          </IconButton>
        )}
      </div>
    </DialogTitle>
  );

  const getDialogOkBtn = () => {
    if (!onOk) {
      return null;
    }

    const okBtn = (
      <Button
        onClick={onOk}
        color="primary"
        className={`${classes.okButton} ${okClassName} modalButton-2`}
        disabled={loading}
        {...okButtonProps}
      >
        {okText}
      </Button>
    );

    return !helpers.isEmpty(popConfirmProps) ? (
      <PopConfirm
        {...popConfirmProps}
      >
        {okBtn}
      </PopConfirm>
    ) : okBtn;
  };

  const getDialogActions = () => (
    <div className={`${classes.buttonBox}${reverseFooterButtons ? ' reverse' : ''}`}>
      {!hideCloseButton && (
        <Button
          onClick={onClose}
          color="primary"
          className={`${classes.closeButton} ${closeClassName} modalButton-1`}
        >
          {closeText}
        </Button>
      )}
      {getDialogOkBtn()}
      {thirdButtonText && (
        <Button
          onClick={onClickThirdButton}
          color="primary"
          className={`${classes.thirdButton} ${thirdButtonClassName} modalButton-2`}
          disabled={loading}
          {...thirdButtonProps}
        >
          {thirdButtonText}
        </Button>
      )}
    </div>
  );

  return (
    <>
      <Dialog
        fullScreen={fullScreen}
        open={isVisible}
        aria-labelledby="responsive-dialog-title"
        scroll="body"
        classes={classesForDialog}
        className={className}
        {...!closeOnClickOnlyTheButton ? { onClose } : {}}
      >
        {getTitle()}
        <DialogContent className={classes.dialogContent}>
          {content}
        </DialogContent>
        <DialogActions>
          {footer || getDialogActions()}
        </DialogActions>
      </Dialog>
      <ModalDialogVideo
        onClose={handleClose}
        isVisible={open}
        videosrc={videoURL}
        title={videoTooltip}
        largeModal
      />
    </>
  );
}

ModalDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  isVisible: PropTypes.bool.isRequired,
  content: PropTypes.node.isRequired,
  loading: PropTypes.bool,
  title: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
  ]),
  titleClassOptions: PropTypes.string,
  footer: PropTypes.node,
  onOk: PropTypes.func,
  okButtonProps: PropTypes.object,
  onClickThirdButton: PropTypes.func,
  thirdButtonProps: PropTypes.object,
  largeModal: PropTypes.bool,
  veryBlackBackground: PropTypes.bool,
  closeText: PropTypes.string,
  okText: PropTypes.string,
  thirdButtonText: PropTypes.string,
  closeOnClickOnlyTheButton: PropTypes.bool,
  isHeaderVisible: PropTypes.bool,
  hideCloseButton: PropTypes.bool,
  className: PropTypes.string,
  closeClassName: PropTypes.oneOf([
    'default',
    'as-ok-btn',
    'white-btn',
  ]),
  okClassName: PropTypes.oneOf([
    'default',
    'white-btn',
  ]),
  thirdButtonClassName: PropTypes.oneOf([
    'default',
    'white-btn',
  ]),
  reverseFooterButtons: PropTypes.bool,
  popConfirmProps: PropTypes.object,
  isCloseIconHid: PropTypes.bool,
  showVideo: PropTypes.bool,
  videoTooltip: PropTypes.string,
  videoURL: PropTypes.string,
};

ModalDialog.defaultProps = {
  loading: false,
  title: null,
  titleClassOptions: '',
  footer: null,
  onOk: null,
  okButtonProps: {},
  onClickThirdButton: null,
  thirdButtonProps: {},
  largeModal: false,
  closeText: 'Close',
  okText: 'Ok',
  closeOnClickOnlyTheButton: false,
  isHeaderVisible: true,
  veryBlackBackground: false,
  hideCloseButton: false,
  className: '',
  closeClassName: 'default',
  okClassName: 'default',
  thirdButtonClassName: 'default',
  reverseFooterButtons: false,
  popConfirmProps: null,
  isCloseIconHid: false,
  showVideo: false,
  videoTooltip: '',
  videoURL: '',
};

export default withStyles(ModalDialog, modalDialogStyles, { withTheme: true });
