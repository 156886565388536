/* eslint-disable react/no-array-index-key */
import React, { lazy, Suspense, Fragment } from 'react';
import { Switch, Redirect, Route } from 'react-router-dom';
import DashboardLayout from 'src/layouts/DashboardLayout';
import MainLayout from 'src/layouts/MainLayout';
import LoadingScreen from 'src/components/LoadingScreen';
import AuthGuard from 'src/components/AuthGuard';
import GuestGuard from 'src/components/GuestGuard';
import MessageView from 'src/views/Settings/MessageView/index';
import HelpAndInformation from 'src/views/pages/HelpAndInformation';
import RedirectLogic from 'src/RedirectLogic';

import { ROUTES } from 'src/constants';

const routesConfig = [
  {
    exact: true,
    path: '/f/:formId',
    component: lazy(() => import('src/forms'))
  },
  {
    exact: true,
    path: '/signupnow',
    component: lazy(() => import('src/signup'))
  },
  {
    exact: true,
    path: '/',
    component: () => <Redirect to="/login" />
  },
  {
    exact: true,
    path: '/404',
    component: lazy(() => import('src/views/pages/Error404View'))
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/login',
    component: lazy(() => import('src/views/auth/LoginView'))
  },
  {
    exact: true,
    path: '/login-unprotected',
    component: lazy(() => import('src/views/auth/LoginView'))
  },
  {
    path: '/app',
    guard: AuthGuard,
    layout: DashboardLayout,
    routes: [
      {
        exact: true,
        path: '/app',
        component: () => <RedirectLogic defaultRedirect="/app/management/FollowUp" />
      },
      {
        exact: true,
        path: ROUTES.APP_ACCOUNT,
        component: lazy(() => import('src/views/pages/AccountView'))
      },
      {
        exact: true,
        path: ROUTES.APP_PARTY_SETTINGS,
        component: lazy(() => import('src/views/pages/PartySettingsView'))
      },
      {
        exact: true,
        path: ROUTES.APP_ORDER_SETTINGS,
        component: lazy(() => import('src/views/pages/OrderSettingsView'))
      },
      {
        exact: true,
        path: '/app/order-settings/follow-ups/:followUpId',
        component: lazy(() => import('src/views/pages/OrderSettingsView/TabFollowUpActions/FollowUpDetails'))
      },
      {
        exact: true,
        path: '/app/management/customers',
        component: lazy(() => import('src/views/management/CustomerListView'))
      },
      {
        exact: true,
        path: '/app/management/customers/create',
        component: lazy(() => import('src/views/management/CustomerCreateView'))
      },
      {
        exact: true,
        path: '/app/management/customers/:customerId/edit',
        component: lazy(() => import('src/views/management/CustomerEditView'))
      },
      {
        exact: true,
        path: '/app/management/customers/:customerId',
        component: lazy(() => import('src/views/management/CustomerDetailsView'))
      },
      {
        exact: true,
        path: '/app/management/parties',
        component: lazy(() => import('src/views/management/PartyListView'))
      },
      {
        exact: true,
        path: '/app/management/parties/create',
        component: lazy(() => import('src/views/management/PartyCreateView'))
      },
      {
        exact: true,
        path: '/app/management/parties/:partyId/edit',
        component: lazy(() => import('src/views/management/PartyEditView'))
      },
      {
        exact: true,
        path: '/app/management/parties/:partyId',
        component: lazy(() => import('src/views/management/PartyDetailsView'))
      },
      {
        exact: true,
        path: '/app/management/parties/smart-actions/create',
        component: lazy(() => import('src/views/management/PartyDetailsView/SmartActions/SmartActionCreateView'))
      },
      {
        exact: true,
        path: '/app/management/parties/smart-actions/:smartActionId',
        component: lazy(() => import('src/views/management/PartyDetailsView/SmartActions/SmartActionDetails'))
      },
      {
        exact: true,
        path: '/app/party-settings/party-venues',
        component: lazy(() => import('src/views/pages/PartySettingsView/TabPartyVenues/TabPartyVenuesListView'))
      },
      {
        exact: true,
        path: '/app/party-settings/party-venues/create',
        component: lazy(() => import('src/views/pages/PartySettingsView/TabPartyVenues/PartyVenueCreate'))
      },
      {
        exact: true,
        path: '/app/party-settings/party-venues/:partyVenueId/edit',
        component: lazy(() => import('src/views/pages/PartySettingsView/TabPartyVenues/PartyVenueEdit'))
      },
      {
        exact: true,
        path: '/app/party-settings/party-venues/:partyVenueId',
        component: lazy(() => import('src/views/pages/PartySettingsView/TabPartyVenues/PartyVenueDetails'))
      },
      {
        exact: true,
        path: '/app/party-settings/party-types',
        component: lazy(() => import('src/views/pages/PartySettingsView/TabPartyTypes/TabPartyTypesListView'))
      },
      {
        exact: true,
        path: '/app/party-settings/party-types/create',
        component: lazy(() => import('src/views/pages/PartySettingsView/TabPartyTypes/PartyTypeCreate'))
      },
      {
        exact: true,
        path: '/app/party-settings/party-types/:partyTypeId/edit',
        component: lazy(() => import('src/views/pages/PartySettingsView/TabPartyTypes/PartyTypeEdit'))
      },
      {
        exact: true,
        path: '/app/party-settings/party-types/:partyTypeId',
        component: lazy(() => import('src/views/pages/PartySettingsView/TabPartyTypes/PartyTypeDetails'))
      },
      {
        exact: true,
        path: '/app/smart-action-groups/smart-actions/create',
        component: lazy(() => import('src/views/pages/SmartActionGroupView/SmartActionCreateView'))
      },
      {
        exact: true,
        path: '/app/smart-action-groups/smart-actions/:smartActionId',
        component: lazy(() => import('src/views/pages/SmartActionGroupView/SmartActionDetails'))
      },
      {
        exact: true,
        path: '/app/form-definition/form-lines/create-image',
        component: lazy(() => import('src/views/pages/FormsDefinitionView/FormDetails/FormLineDefinitionCreateImageView'))
      },
      {
        exact: true,
        path: '/app/form-definition/form-lines/create-text',
        component: lazy(() => import('src/views/pages/FormsDefinitionView/FormDetails/FormLineDefinitionCreateTextView'))
      },
      {
        exact: true,
        path: '/app/form-definition/form-lines/create-submitted-image',
        component: lazy(() => import('src/views/pages/FormsDefinitionView/FormDetails/FormLineDefinitionCreateSubmittedImageView'))
      },
      {
        exact: true,
        path: '/app/form-definition/form-lines/create-submitted-text',
        component: lazy(() => import('src/views/pages/FormsDefinitionView/FormDetails/FormLineDefinitionCreateSubmittedTextView'))
      },
      {
        exact: true,
        path: '/app/form-definition/form-lines/:formLineDefinitionId',
        component: lazy(() => import('src/views/pages/FormsDefinitionView/FormDetails/FormLineDefinitionDetails'))
      },
      {
        exact: true,
        path: '/app/form/form-lines/create-image',
        component: lazy(() => import('src/views/management/PartyDetailsView/JoinForm/FormLineCreateImageView'))
      },
      {
        exact: true,
        path: '/app/form/form-lines/create-text',
        component: lazy(() => import('src/views/management/PartyDetailsView/JoinForm/FormLineCreateTextView'))
      },
      {
        exact: true,
        path: '/app/form/form-lines/create-submitted-image',
        component: lazy(() => import('src/views/management/PartyDetailsView/JoinForm/FormLineCreateSubmittedImageView'))
      },
      {
        exact: true,
        path: '/app/form/form-lines/create-submitted-text',
        component: lazy(() => import('src/views/management/PartyDetailsView/JoinForm/FormLineCreateSubmittedTextView'))
      },
      {
        exact: true,
        path: '/app/form/form-lines/:formLineId',
        component: lazy(() => import('src/views/management/PartyDetailsView/JoinForm/FormLineDetails'))
      },
      {
        exact: true,
        path: '/app/management/followUp',
        component: lazy(() => import('src/views/management/FollowUp'))
      },
      {
        exact: true,
        path: '/app/management/orders',
        component: lazy(() => import('src/views/management/OrderListView'))
      },
      {
        exact: true,
        path: '/app/management/orders/create',
        component: lazy(() => import('src/views/management/OrderCreateView'))
      },
      {
        exact: true,
        path: '/app/management/orders/:orderId',
        component: lazy(() => import('src/views/management/OrderDetailsView'))
      },
      {
        exact: true,
        path: '/app/management/orders/:orderId/edit',
        component: lazy(() => import('src/views/management/OrderEditFullView'))
      },
      {
        exact: true,
        path: '/app/management',
        component: () => <Redirect to="/app/management/customers" />
      },
      {
        exact: true,
        path: '/app/management',
        component: () => <Redirect to="/app/management/parties" />
      },
      {
        exact: true,
        path: '/app/settings/messages',
        component: () => <MessageView />
      },
      {
        exact: true,
        path: '/app/settings',
        component: () => <Redirect to="/app/account" />
      },
      {
        exact: true,
        path: '/app/party-settings',
        component: () => <Redirect to="/app/party-settings" />
      },
      {
        exact: true,
        path: '/app/order-settings',
        component: () => <Redirect to="/app/order-settings" />
      },
      {
        exact: true,
        path: ROUTES.APP_HELP_AND_INFORMATION,
        component: () => <HelpAndInformation />
      },
      {
        component: () => <Redirect to="/404" />
      }
    ]
  },
  {
    path: '*',
    layout: MainLayout,
    routes: [
      {
        component: () => <Redirect to="/404" />
      }
    ]
  }
];

const renderRoutes = (routes) => (routes ? (
  <Suspense fallback={<LoadingScreen />}>
    <Switch>
      {routes.map((route, i) => {
        const Guard = route.guard || Fragment;
        const Layout = route.layout || Fragment;
        const Component = route.component;

        return (
          <Route
            key={i}
            path={route.path}
            exact={route.exact}
            render={(props) => (
              <Guard>
                <Layout>{route.routes ? renderRoutes(route.routes) : <Component {...props} />}</Layout>
              </Guard>
            )}
          />
        );
      })}
    </Switch>
  </Suspense>
) : null);

function Routes() {
  return renderRoutes(routesConfig);
}

export default Routes;
