import axios from 'src/utils/axios';
import { addNotification } from 'src/actions/notificationActions';
import { NOTIFICATION_STATUS } from 'src/constants';
import helpers from 'src/helpers';

export const SET_PARTY_TYPES = '@partytypes/set-party-types';
export const GET_PARTY_TYPES = '@partytypes/get-party-types';
export const GET_PARTY_TYPES_SUCCESS = '@partytypes/get-party-types-success';
export const GET_PARTY_TYPES_ERROR = '@partytypes/get-party-types-error';

export const SET_PARTY_TYPES_QUERY = '@partytypes/set-party-types-query';
export const SET_BACK_TO_PARTY_TYPES_LIST = '@partytypes/set-back-to-party-types-list';

export const getPartyTypes = () => async (dispatch, getState) => {
  dispatch({
    type: GET_PARTY_TYPES,
  });
  try {
    const response = await axios.post('/api/partySettings/partyTypes', getState().partyTypes.query);
    dispatch({
      type: GET_PARTY_TYPES_SUCCESS,
      payload: response?.data || {}
    });
  } catch (e) {
    const {
      error,
      message,
    } = e?.response?.data || {};
    dispatch(addNotification(GET_PARTY_TYPES_ERROR, helpers.transform.toString(message || error || 'Server error'), NOTIFICATION_STATUS.ERROR));
    dispatch({
      type: GET_PARTY_TYPES_ERROR,
    });
  }
};

export const setPartyTypes = (partyTypes) => ({
  type: SET_PARTY_TYPES,
  payload: partyTypes
});

export const setPartyTypesQuery = (query) => ({
  type: SET_PARTY_TYPES_QUERY,
  payload: query
});

export const setBackToPartyTypesList = (payload) => ({
  type: SET_BACK_TO_PARTY_TYPES_LIST,
  payload,
});

export const clearBackToPartyTypesList = (clearAll = false) => ({
  type: SET_BACK_TO_PARTY_TYPES_LIST,
  payload: !clearAll ? {
    partyTypes: [],
    query: {},
  } : false,
});
