import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import moment from 'moment';
import MessageBox from 'src/components/MessageBox';
import { ROUTES } from 'src/constants';
import { setCurrentNavigationTab } from 'src/actions/accountSettingsActions';
import helpers from 'src/helpers';

function DashboardLayoutTopMessage() {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.account);
  const {
    isTrialVersionWithoutSubscription,
    subscriptionOff,
    userSubscription,
  } = user || {};
  const { isSubscriptionActive } = userSubscription || {};

  const handleClickSubscribe = () => {
    dispatch(setCurrentNavigationTab('subscription'));
  };

  if (helpers.isPageBlocked(user) || subscriptionOff) {
    return null;
  }

  // if (helpers.isDateAfterCurrent(isTrialVersionWithoutSubscription) && !isSubscriptionActive) {
  if (isTrialVersionWithoutSubscription && !isSubscriptionActive) {
    return (
      <MessageBox
        message={(
          <>
            Your TRIAL period ends in
            <span className="nbsp" />
            {helpers.transform.toDayDifferent(isTrialVersionWithoutSubscription, moment())}
            <span className="nbsp" />
            day(s)!
            <span className="nbsp xs-full" />
            Please
            <span className="nbsp" />
            <Link
              className="a-inherit"
              to={ROUTES.APP_ACCOUNT}
              onClick={handleClickSubscribe}
            >
              Subscribe
            </Link>
            . You won’t be billed until trial ends.
          </>
        )}
        type={helpers.isDateAfterCurrent(isTrialVersionWithoutSubscription, moment().utc().add(8, 'd')) ? 'type-3-light' : 'type-3'}
        paddingTop="0"
        paddingBottom="0"
        visible
        fullWidth
      />
    );
  }

  // if (!helpers.doesUserHaveSubscriptionOrTrial(user)) {
  if (!helpers.doesUserHaveSubscription(user)) {
    return (
      <MessageBox
        message={(
          <>
            Your TRIAL period has ended!
            <span className="nbsp xs-full" />
            Please
            <span className="nbsp" />
            <Link
              className="a-inherit"
              to={ROUTES.APP_ACCOUNT}
              onClick={handleClickSubscribe}
            >
              Subscribe
            </Link>
            .
          </>
        )}
        type="type-3-red"
        paddingTop="0"
        paddingBottom="0"
        visible
        fullWidth
      />
    );
  }

  return null;
}

export default DashboardLayoutTopMessage;
