/* eslint-disable no-param-reassign */
import produce from 'immer';
import {
  SET_PARTY_TYPES,
  GET_PARTY_TYPES,
  GET_PARTY_TYPES_SUCCESS,
  GET_PARTY_TYPES_ERROR,
  SET_PARTY_TYPES_QUERY,
  SET_BACK_TO_PARTY_TYPES_LIST,
} from 'src/actions/partyTypesActions';

const backToPartyTypesList = {
  partyTypes: [],
  query: {},
  active: false,
  comeBack: false,
};

const initialState = {
  partyTypes: [],
  query: {
    status: 'Active',
  },
  visibleModalFormNumber: null,
  backToPartyTypesList,
  loading: false,
};

const partyTypesReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case SET_PARTY_TYPES: {
      return produce(state, (draft) => {
        draft.partyTypes = payload || [];
      });
    }

    case GET_PARTY_TYPES: {
      return produce(state, (draft) => {
        draft.loading = true;
      });
    }

    case GET_PARTY_TYPES_SUCCESS: {
      return produce(state, (draft) => {
        draft.partyTypes = payload?.partyTypes || [];
        draft.query.count = payload?.count || 0;
        draft.loading = false;
      });
    }

    case GET_PARTY_TYPES_ERROR: {
      return produce(state, (draft) => {
        draft.loading = false;
      });
    }

    case SET_PARTY_TYPES_QUERY: {
      return produce(state, (draft) => {
        draft.query = payload ? {
          ...state.query,
          ...payload
        } : initialState.query;
      });
    }

    case SET_BACK_TO_PARTY_TYPES_LIST: {
      return produce(state, (draft) => {
        draft.backToPartyTypesList = payload ? {
          ...state.backToPartyTypesList,
          ...payload,
        } : backToPartyTypesList;
      });
    }

    default: {
      return state;
    }
  }
};

export default partyTypesReducer;
