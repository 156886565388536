import { STYLE_OPTIONS } from 'src/constants';

const colorRed = '#ED3F3F';

const textFieldFormikStyle = {
  root: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'start',

    '&.small .MuiInputBase-input': {
      paddingTop: 8,
      paddingBottom: 8,
    },
    '&.small .MuiFormControlLabel-root': {
      marginLeft: -8,

      '& .MuiFormControlLabel-label': {
        fontSize: '14px',
        fontWeight: 400,
      },

      '& .MuiSvgIcon-root': {
        width: 17,
        height: 17,
      },
    },
  },

  textField: {
    width: '100%',

    '&.highlightsType1': {
      '& .MuiInputBase-root': {
        backgroundColor: STYLE_OPTIONS.COLOR_ACCOUNT_SETUP_FIELD_HIGHLIGHTS,
      },
    },

    '& .MuiFormLabel-asterisk, & .MuiInputLabel-asterisk': {
      color: colorRed,
    },

    '&.disabledBox .MuiInputBase-root.Mui-disabled': {
      backgroundColor: '#F4F4F4',
    },

    '&.disabledBox .MuiInputBase-input.Mui-disabled': {
      color: '#393939',
    },

    '&.disabledBox .MuiInputBase-root.Mui-disabled fieldset': {
      border: 0,
    },

    '& .MuiInputLabel-outlined': {
      maxWidth: 'calc(100% - 30px)',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',

      '&.MuiInputLabel-shrink': {
        maxWidth: 'calc(100% + 20px)',
      }
    },

    '& .MuiInputAdornment-root .MuiButtonBase-root': {
      padding: 0,
    },
  },

  checkbox: {
    marginTop: 6,

    '& .MuiTypography-body1': {
      marginLeft: 8,
    },
  },

  radio: {
    marginTop: 0,

    '& .MuiTypography-body1': {
      marginLeft: 8,
    },

    '& .Mui-checked + span': {
      color: STYLE_OPTIONS.COLOR_BLUE,
    },
  },

  info: {
    marginTop: 17,
  },

  redirect: {
    marginTop: 5,
    marginRight: -15,
  },
};

export default textFieldFormikStyle;
