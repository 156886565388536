import React from 'react';
import PropTypes from 'prop-types';

function TOSAndPrivacyStatementText({ classes }) {
  return (
    <p className={classes.text}>
      Please review and agree to our
      {' '}
      <a target="_blank" href="/static/pdf/terms-of-service.pdf">Terms of Service</a>
      {' '}
      and
      {' '}
      <a target="_blank" href="/static/pdf/privacy-policy.pdf">Privacy Policy</a>
      {' '}
      to continue.
    </p>
  );
}

TOSAndPrivacyStatementText.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default TOSAndPrivacyStatementText;
