import axios from 'src/utils/axios';
import { NOTIFICATION_STATUS } from 'src/constants';
import { addNotification } from 'src/actions/notificationActions';

export const SET_SMART_ACTION_QUERY = '@smartactions/set-smart-action-query';
export const CLEAR_SMART_ACTIONS_QUERY_FILTER = '@smartactions/clear-smart-actions-query-filter';

export const SET_SMART_ACTIONS = '@smartactions/set-smart-actions';
export const GET_SMART_ACTIONS = '@smartactions/get-smart-actions';
export const GET_SMART_ACTIONS_SUCCESS = '@smartactions/get-smart-actions-success';
export const GET_SMART_ACTIONS_ERROR = '@smartactions/get-smart-actions-error';

export const VERIFY_CODE = '@smartactions/verify-code';
export const VERIFY_CODE_SUCCESS = '@smartactions/verify-code-success';
export const VERIFY_CODE_ERROR = '@smartactions/verify-code-error';

export const APPLY_SHARE_CODE = '@smartactions/apply-share-code';
export const APPLY_SHARE_CODE_SUCCESS = '@smartactions/apply-share-code-success';
export const APPLY_SHARE_CODE_ERROR = '@smartactions/apply-share-code-error';

export const SET_SMART_ACTIONS_QUERY = '@smartactions/set-smart-actions-query';

export const SET_SELECTED_SMART_ACTIONS = '@smartactions/set-selected-smart-actions';

export const SET_BACK_TO_SMART_ACTION_LIST = '@smartactions/set-back-to-smart-action-list';

export const SET_SMART_ACTIONS_PARTY_TYPE = '@smartactions/set-smart-actions-party-type';

export const SET_VISIBLE_MODAL_FORM_NUMBER = '@smartactions/set-visible-modal-form-number';

export const SET_VALUES_FORM_APPLY_SHARE_CODE = '@smartactions/set-values-form-apply-share-code';

export const getSmartActions = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: GET_SMART_ACTIONS,
    });
    const { smartActions } = getState();
    const {
      partyTypeId,
      smartActionGroupId,
      query,
    } = smartActions || {};
    const params = {
      partyTypeId,
      smartActionGroupId,
      query,
    };
    const response = await axios.post('/api/smartActions', { params });
    dispatch({
      type: GET_SMART_ACTIONS_SUCCESS,
      payload: response?.data || {}
    });
  } catch (e) {
    const {
      message,
      error,
    } = e?.response?.data || {};
    const { message: errorMessage } = error || {};
    dispatch(addNotification(GET_SMART_ACTIONS_ERROR, message || errorMessage || 'Try again later', NOTIFICATION_STATUS.ERROR));
    dispatch({
      type: GET_SMART_ACTIONS_ERROR,
    });
  }
};

export const verifyCode = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: VERIFY_CODE,
    });
    const {
      formApplyShareCode
    } = getState().smartActions || {};
    const {
      shareCode,
    } = formApplyShareCode || {};
    const response = await axios.post('/api/smartActions/verifyShareCode', { shareCode });
    const {
      smartActionName,
    } = response?.data || {};

    if (!smartActionName) {
      dispatch(addNotification(VERIFY_CODE_ERROR, 'Could Not Verify Share Code', NOTIFICATION_STATUS.ERROR));
      dispatch({
        type: VERIFY_CODE_ERROR,
      });
      return;
    }

    dispatch(addNotification(VERIFY_CODE_SUCCESS, 'Valid Share Code - You Can Apply Now', NOTIFICATION_STATUS.SUCCESS));

    dispatch({
      type: VERIFY_CODE_SUCCESS,
      payload: response?.data || {}
    });
  } catch (e) {
    const {
      message,
      error,
    } = e?.response?.data || {};
    const { message: errorMessage } = error || {};
    dispatch(addNotification(VERIFY_CODE_ERROR, message || errorMessage || error || 'Try again later', NOTIFICATION_STATUS.ERROR));
    dispatch({
      type: VERIFY_CODE_ERROR,
    });
  }
};

export const applyShareCode = (doWhenTry = false, doWhenCatch = false) => async (dispatch, getState) => {
  try {
    dispatch({
      type: APPLY_SHARE_CODE,
    });
    const {
      partyTypeId,
      formApplyShareCode
    } = getState().smartActions || {};
    const {
      shareCode,
    } = formApplyShareCode || {};
    const params = {
      partyTypeId,
      shareCode,
    };
    const response = await axios.post('/api/smartActions/applyShareCode', { params });
    const {
      success,
    } = response?.data || {};

    if (!success) {
      dispatch(addNotification(APPLY_SHARE_CODE_ERROR, 'Could Not (Fully) Apply Share Code', NOTIFICATION_STATUS.ERROR));
      dispatch({
        type: APPLY_SHARE_CODE_ERROR,
      });
      return;
    }

    dispatch(addNotification(APPLY_SHARE_CODE_SUCCESS, 'Successfully Applied the Share Code', NOTIFICATION_STATUS.SUCCESS));

    dispatch({
      type: APPLY_SHARE_CODE_SUCCESS,
    });

    if (doWhenTry) {
      doWhenTry();
    }
  } catch (e) {
    const {
      message,
      error,
    } = e?.response?.data || {};
    const { message: errorMessage } = error || {};
    dispatch(addNotification(APPLY_SHARE_CODE_ERROR, message || errorMessage || error || 'Try again later', NOTIFICATION_STATUS.ERROR));
    dispatch({
      type: APPLY_SHARE_CODE_ERROR,
    });
    if (doWhenCatch) {
      doWhenCatch(e);
    }
  }
};

export const setSmartActions = (smartActions) => ({
  type: SET_SMART_ACTIONS,
  payload: smartActions
});

export const setSmartActionsQuery = (query) => ({
  type: SET_SMART_ACTIONS_QUERY,
  payload: query
});

export const clearSmartActionsQueryFilter = () => ({
  type: CLEAR_SMART_ACTIONS_QUERY_FILTER,
});

export const setSelectedSmartActions = (selectedSmartActions) => ({
  type: SET_SELECTED_SMART_ACTIONS,
  payload: selectedSmartActions
});

export const setBackToSmartActionList = (payload) => ({
  type: SET_BACK_TO_SMART_ACTION_LIST,
  payload,
});

export const clearBackToSmartActionList = (clearAll = false) => ({
  type: SET_BACK_TO_SMART_ACTION_LIST,
  payload: !clearAll ? {
    smartActions: [],
    query: {},
  } : false,
});

export const setSmartActionsPartyType = (payload) => ({
  type: SET_SMART_ACTIONS_PARTY_TYPE,
  payload,
});

export const setVisibleModalFormNumber = (modalNumber) => ({
  type: SET_VISIBLE_MODAL_FORM_NUMBER,
  payload: modalNumber
});

export const setValuesFormApplyShareCode = (values) => ({
  type: SET_VALUES_FORM_APPLY_SHARE_CODE,
  payload: values
});
