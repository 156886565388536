/* eslint-disable no-param-reassign */
import produce from 'immer';
import {
  GET_SMART_ACTIONS,
  GET_SMART_ACTIONS_SUCCESS,
  GET_SMART_ACTIONS_ERROR,
  SET_SMART_ACTIONS_QUERY,
  SET_SELECTED_SMART_ACTIONS,
  SET_BACK_TO_SMART_ACTION_LIST,
  SET_SMART_ACTIONS_PARTY,
  SET_VISIBLE_MODAL_FORM_NUMBER,
  SET_VALUES_FORM_APPLY_SHARE_CODE,
  SET_VALUES_FORM_ADJUST_DAYS_HOURS,
  VERIFY_CODE,
  VERIFY_CODE_SUCCESS,
  VERIFY_CODE_ERROR,
  APPLY_SHARE_CODE,
  APPLY_SHARE_CODE_SUCCESS,
  APPLY_SHARE_CODE_ERROR,
  ADJUST_DAYS_HOURS,
  ADJUST_DAYS_HOURS_SUCCESS,
  ADJUST_DAYS_HOURS_ERROR,
  RESET_ACTIONS,
  RESET_ACTIONS_SUCCESS,
  RESET_ACTIONS_ERROR,
  SKIP_UNSKIP_ACTIONS,
  SKIP_UNSKIP_ACTIONS_SUCCESS,
  SKIP_UNSKIP_ACTIONS_ERROR,
  DELETE_ACTIONS,
  DELETE_ACTIONS_SUCCESS,
  DELETE_ACTIONS_ERROR,
} from 'src/actions/scheduledSmartActionsActions';

const query = {
  search: '',
  status: 'all',
  sort: [['toExecuteDateTime', 'asc']],
  count: 0,
};

const backToSmartActionList = {
  smartActionGroupId: null,
  smartActions: [],
  query: {},
  active: false,
  comeBack: false,
};

const formApplyShareCode = {
  loading: false,
  shareCode: '',
  validCode: true,
  smartActionOwnerFirstName: '',
  smartActionOwnerLastName: '',
  smartActionName: '',
};

const formAdjustDaysHours = {
  loading: false,
  numberDaysToAdjust: '',
  validNumberOfDays: true,
  numberHoursToAdjust: '',
  validNumberOfHours: true,
};

const initialState = {
  partyId: null,
  smartActionGroup: {},
  smartActionGroupId: null,
  smartActionGroupShareCode: null,
  smartActions: [],
  selectedSmartActions: [],
  loading: false,
  query,
  backToSmartActionList,
  visibleModalFormNumber: null,
  formApplyShareCode,
  formAdjustDaysHours,
};

const partySmartActionsReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_SMART_ACTIONS: {
      return produce(state, (draft) => {
        draft.loading = true;
      });
    }

    case GET_SMART_ACTIONS_SUCCESS: {
      return produce(state, (draft) => {
        draft.smartActions = payload?.scheduledSmartActions || [];
        draft.smartActionGroupId = payload?.scheduledActionGroupId || null;
        draft.query.count = payload?.count || 0;
        draft.loading = false;
        draft.selectedSmartActions = [];
      });
    }

    case GET_SMART_ACTIONS_ERROR: {
      return produce(state, (draft) => {
        draft.loading = false;
      });
    }

    case VERIFY_CODE: {
      return produce(state, (draft) => {
        draft.formApplyShareCode.loading = true;
      });
    }

    case VERIFY_CODE_SUCCESS: {
      return produce(state, (draft) => {
        draft.formApplyShareCode.validCode = true;
        draft.formApplyShareCode.loading = false;
        draft.formApplyShareCode.smartActionOwnerFirstName = payload?.smartActionOwnerFirstName || [];
        draft.formApplyShareCode.smartActionOwnerLastName = payload?.smartActionOwnerLastName || [];
        draft.formApplyShareCode.smartActionName = payload?.smartActionName || [];
      });
    }

    case VERIFY_CODE_ERROR: {
      return produce(state, (draft) => {
        draft.formApplyShareCode.loading = false;
        draft.formApplyShareCode.validCode = false;
      });
    }

    case SET_SMART_ACTIONS_QUERY: {
      return produce(state, (draft) => {
        draft.query = payload ? {
          ...state.query,
          ...payload
        } : initialState.query;
      });
    }

    case SET_SELECTED_SMART_ACTIONS: {
      return produce(state, (draft) => {
        draft.selectedSmartActions = payload || [];
      });
    }

    case SET_BACK_TO_SMART_ACTION_LIST: {
      return produce(state, (draft) => {
        draft.backToSmartActionList = payload ? {
          ...state.backToSmartActionList,
          ...payload,
        } : backToSmartActionList;
      });
    }

    case SET_SMART_ACTIONS_PARTY: {
      return produce(state, (draft) => {
        draft.partyId = payload || [];
      });
    }

    case SET_VISIBLE_MODAL_FORM_NUMBER: {
      return produce(state, (draft) => {
        draft.visibleModalFormNumber = payload || null;
      });
    }

    case SET_VALUES_FORM_APPLY_SHARE_CODE: {
      return produce(state, (draft) => {
        draft.formApplyShareCode = payload ? {
          ...state.formApplyShareCode,
          ...payload,
        } : formApplyShareCode;
      });
    }

    case APPLY_SHARE_CODE: {
      return produce(state, (draft) => {
        draft.loading = true;
      });
    }

    case APPLY_SHARE_CODE_SUCCESS: {
      return produce(state, (draft) => {
        draft.loading = false;
      });
    }

    case APPLY_SHARE_CODE_ERROR: {
      return produce(state, (draft) => {
        draft.loading = false;
      });
    }

    case SET_VALUES_FORM_ADJUST_DAYS_HOURS: {
      return produce(state, (draft) => {
        draft.formAdjustDaysHours = payload ? {
          ...state.formAdjustDaysHours,
          ...payload,
        } : formAdjustDaysHours;
      });
    }

    case ADJUST_DAYS_HOURS: {
      return produce(state, (draft) => {
        draft.loading = true;
      });
    }

    case ADJUST_DAYS_HOURS_SUCCESS: {
      return produce(state, (draft) => {
        draft.loading = false;
      });
    }

    case ADJUST_DAYS_HOURS_ERROR: {
      return produce(state, (draft) => {
        draft.loading = false;
      });
    }

    case RESET_ACTIONS: {
      return produce(state, (draft) => {
        draft.loading = true;
      });
    }

    case RESET_ACTIONS_SUCCESS: {
      return produce(state, (draft) => {
        draft.loading = false;
      });
    }

    case RESET_ACTIONS_ERROR: {
      return produce(state, (draft) => {
        draft.loading = false;
      });
    }

    case SKIP_UNSKIP_ACTIONS: {
      return produce(state, (draft) => {
        draft.loading = true;
      });
    }

    case SKIP_UNSKIP_ACTIONS_SUCCESS: {
      return produce(state, (draft) => {
        draft.loading = false;
      });
    }

    case SKIP_UNSKIP_ACTIONS_ERROR: {
      return produce(state, (draft) => {
        draft.loading = false;
      });
    }

    case DELETE_ACTIONS: {
      return produce(state, (draft) => {
        draft.loading = true;
      });
    }

    case DELETE_ACTIONS_SUCCESS: {
      return produce(state, (draft) => {
        draft.loading = false;
      });
    }

    case DELETE_ACTIONS_ERROR: {
      return produce(state, (draft) => {
        draft.loading = false;
      });
    }

    default: {
      return state;
    }
  }
};

export default partySmartActionsReducer;
