/* eslint-disable no-param-reassign */
import produce from 'immer';
import {
  GET_FORM_LINE,
  GET_FORM_LINE_SUCCESS,
  GET_FORM_LINE_ERROR,
  DELETE_FORM_LINE,
  DELETE_FORM_LINE_SUCCESS,
  DELETE_FORM_LINE_ERROR,
  CLEAR_FORM_LINE_WAS_DELETED,
  UPDATE_FORM_LINE,
  UPDATE_FORM_LINE_SUCCESS,
  UPDATE_FORM_LINE_ERROR,
  CREATE_FORM_LINE,
  CREATE_FORM_LINE_SUCCESS,
  CREATE_FORM_LINE_ERROR,
  SET_FORM_LINE_DETAILS_TAB,
  SET_FORM_FORM_LINE,
  SET_VALUES_FORM_LINE_IMAGE,
  SET_FORM_LINE_ID,
} from 'src/actions/formPartyJoinDetailsActions';

const formLineImage = {
  image: false,
  imageContainer: null,
  loading: false,
};

const initialState = {
  formId: null,
  form: {},
  formWasDeleted: false,
  loading: false,
  currentNavigationTab: 'formLine',
  formLineImage,
};

const formPartyJoinDetailsReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_FORM_LINE: {
      return produce(state, (draft) => {
        draft.loading = true;
      });
    }

    case GET_FORM_LINE_SUCCESS: {
      return produce(state, (draft) => {
        draft.form = payload?.form || {};
        draft.loading = false;
      });
    }

    case GET_FORM_LINE_ERROR: {
      return produce(state, (draft) => {
        draft.loading = false;
      });
    }

    case DELETE_FORM_LINE: {
      return produce(state, (draft) => {
        draft.loading = true;
      });
    }

    case DELETE_FORM_LINE_SUCCESS: {
      return produce(state, (draft) => {
        draft.form = payload ? initialState.form : state.form;
        draft.loading = false;
        draft.formWasDeleted = true;
      });
    }

    case DELETE_FORM_LINE_ERROR: {
      return produce(state, (draft) => {
        draft.loading = false;
      });
    }

    case CLEAR_FORM_LINE_WAS_DELETED: {
      return produce(state, (draft) => {
        draft.formWasDeleted = false;
      });
    }

    case CREATE_FORM_LINE:
    case UPDATE_FORM_LINE: {
      return produce(state, (draft) => {
        draft.loading = true;
      });
    }

    case CREATE_FORM_LINE_SUCCESS:
    case CREATE_FORM_LINE_ERROR:
    case UPDATE_FORM_LINE_SUCCESS:
    case UPDATE_FORM_LINE_ERROR: {
      return produce(state, (draft) => {
        draft.loading = false;
      });
    }

    case SET_FORM_LINE_DETAILS_TAB: {
      return produce(state, (draft) => {
        draft.currentNavigationTab = payload || initialState.currentNavigationTab;
      });
    }

    case SET_FORM_FORM_LINE: {
      return produce(state, (draft) => {
        draft.form = payload ? {
          ...state.form,
          ...payload,
        } : initialState.form;
      });
    }

    case SET_VALUES_FORM_LINE_IMAGE: {
      return produce(state, (draft) => {
        draft.formLineImage = payload ? {
          ...state.formLineImage,
          ...payload,
        } : formLineImage;
      });
    }

    case SET_FORM_LINE_ID: {
      return produce(state, (draft) => {
        draft.formId = payload || null;
      });
    }

    default: {
      return state;
    }
  }
};

export default formPartyJoinDetailsReducer;
