import { STYLE_OPTIONS } from 'src/constants';

const fontFamily = STYLE_OPTIONS.FONT_FAMILY;
const color = '#6200EE';
const colorSecondary = '#BFBDC1';
const colorDisabled = '#CCCCCC';
const btnDisabled = {
  background: colorDisabled,
  boxShadow: `0px 0px 0px 1px ${colorDisabled}`,
  color: '#777777',
};
const whiteBtn = {
  color,
  boxShadow: `0px 0px 0px 1px ${color}`,
  backgroundColor: '#ffffff',
  '&:hover': {
    backgroundColor: 'rgba(38, 50, 56, 0.04)',
  },
};
const okButton = {
  color: '#ffffff',
  background: color,
  boxShadow: `0px 0px 0px 1px ${color}`,
  '&:hover': {
    color,
    boxShadow: `0px 0px 0px 1px ${color}`,
    backgroundColor: 'rgba(57, 73, 171, 0.04)',
  },
  '&.Mui-disabled, &.Mui-disabled.primary2, &.white-btn.Mui-disabled, &.white-btn.Mui-disabled.primary2': btnDisabled,
  '&.white-btn': whiteBtn,
};

const modalDialogStyles = (theme, _params, classes) => ({
  root: {
    '&.yellowBg': {
      '& .MuiPaper-root': {
        position: 'relative',
        backgroundColor: STYLE_OPTIONS.COLOR_ACCOUNT_SETUP_FIELD_HIGHLIGHTS,
        '&:before': {
          position: 'absolute',
          top: 0,
          left: 0,
          display: 'block',
          width: '100%',
          height: '100%',
          backgroundColor: '#ffffff',
          content: '" "',
          zIndex: -1,
        },
      },
    },
  },
  title: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    alignItems: 'center',
    '&.top': {
      alignItems: 'flex-start',
    }
  },
  titleInner: {
    width: '100%',
    textAlign: 'center',
    fontSize: 24,
    fontWeight: 500,
    marginTop: 0,
    paddingLeft: 49,
    color: '#393939',
    '& .icon': {
      display: 'block',
      margin: '0px auto',
      fontSize: '40px',
      lineHeight: '1em',
      '&.red': {
        color: STYLE_OPTIONS.COLOR_RED,
      },
      '& svg': {
        fontSize: 'inherit',
      },
    },
    '&.pl0': {
      paddingLeft: '0px !important',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: 18,
      lineHeight: '1.3em',
      marginTop: 0,
      paddingLeft: 40
    }
  },
  buttonBox: {
    textAlign: 'center',
    padding: '0px 17px',
    width: '100%',

    '&.reverse': {
      display: 'flex',
      justifyContent: 'center',
      flexWrap: 'wrap',

      '& .modalButton-1': {
        order: 2,
      },

      '& .modalButton-2': {
        order: 1,
      },
    },

    '& button': {
      fontFamily,
      textTransform: 'none',
      marginLeft: 15,
      marginRight: 15,
      fontWeight: 400,
      fontSize: '16px',
      lineHeight: '16px',
      padding: '12px 24px',
      marginTop: 10,
      marginBottom: 10,
    },
  },
  okButton,
  closeButton: {
    color: '#ffffff',
    background: colorSecondary,
    boxShadow: `0px 0px 0px 1px ${colorSecondary}`,

    '&:hover': {
      color: colorSecondary,
      boxShadow: `0px 0px 0px 1px ${colorSecondary}`
    },
    '&.Mui-disabled': {
      background: colorDisabled,
    },
    '&.as-ok-btn': okButton,
    '&.white-btn': {
      ...whiteBtn,
      '&.Mui-disabled, &.Mui-disabled.primary2': btnDisabled,
    },
  },
  largeDialogPaper: {
    maxWidth: 987,
    width: '90%',
    [`& .${classes.titleInner}`]: {
      marginTop: 39,
    },
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
    [theme.breakpoints.down('sm')]: {
      [`& .${classes.titleInner}`]: {
        marginTop: 0,
      }
    }
  },
  dialogContainerBlack: {
    backgroundColor: 'rgba(0, 0, 0, 0.95)',
  },

  dialogContent: {
    '& .center': {
      textAlign: 'center',
    },
  },
});

export default modalDialogStyles;
