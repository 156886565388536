/* eslint-disable no-param-reassign */
import produce from 'immer';
import {
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  LOGOUT,
  SILENT_LOGIN,
  UPDATE_PROFILE,
  GET_ACCOUNT_INFO,
  GET_USERS_LIST,
  GET_USERS_LIST_SUCCESS,
  GET_USERS_LIST_ERROR,
  SIGN_IN_AS_USER,
  SIGN_IN_AS_USER_ERROR,
  SIGN_IN_AS_USER_SUCCESS,
  CHANGE_COMPANY_TITLE,
  CHANGE_COMPANY_TITLE_ERROR,
  CHANGE_COMPANY_TITLE_SUCCESS,
  CHANGE_CAMPAIGNS,
  CHANGE_CAMPAIGNS_ERROR,
  CHANGE_CAMPAIGNS_SUCCESS,
  SET_USER_WAS_SHOWN_ERRORS,
} from 'src/actions/accountActions';
import {
  GET_USER_SUBSCRIPTION_SUCCESS,
  SUBSCRIBE_SUCCESS,
  UNSUBSCRIBE_SUCCESS,
  CANCEL_SUBSCRIPTION_AT_PERIOD_END_SUCCESS,
  UPDATE_SUBSCRIPTION_PAYMENT_METHOD_SUCCESS,
  CREATE_REFERRAL_CODE_SUCCESS,
  APPLY_REFERRAL_CODE_SUCCESS,
  RETRIEVE_USER_SUBSCRIPTION,
} from 'src/actions/accountSettingsActions';

const initialState = {
  azAccountName: '',
  databaseBeingUsed: '',
  signInErrors: [],
  campaignNamesToChange: [],
  newUpdatedCampaignsList: [],
  updateUsersTitle: false,
  companyTitles: [],
  updateCompanyTitleLoading: false,
  updateCampaignLoading: false,
  user: null,
  usersList: {
    users: [],
    loading: false,
  },
  userWasShownErrors: [],
  loginLoading: false,
};

const accountReducer = (state = initialState, action) => {
  const {
    payload,
    type
  } = action;
  switch (type) {
    case LOGIN_REQUEST: {
      return produce(state, (draft) => {
        // Ensure we clear current session
        draft.user = null;
        draft.updateCompanyTitleLoading = true;
        draft.loginLoading = true;
      });
    }

    case LOGIN_SUCCESS: {
      const {
        user,
        azAccountName,
        databaseBeingUsed,
        signInErrors = initialState.signInErrors,
        campaignNamesToChange = initialState.campaignNamesToChange,
        campaignsList = initialState.newUpdatedCampaignsList,
        updateUsersTitle = initialState.updateUsersTitle,
        companyTitles = initialState.companyTitles,
      } = payload;

      return produce(state, (draft) => {
        draft.user = user;
        draft.azAccountName = azAccountName;
        draft.databaseBeingUsed = databaseBeingUsed;
        draft.signInErrors = signInErrors;
        draft.campaignNamesToChange = campaignNamesToChange;
        draft.newUpdatedCampaignsList = campaignsList;
        draft.updateUsersTitle = updateUsersTitle;
        draft.companyTitles = companyTitles;
        draft.updateCompanyTitleLoading = false;
        draft.userWasShownErrors = initialState.userWasShownErrors;
        draft.loginLoading = false;
      });
    }

    case LOGIN_FAILURE: {
      return produce(state, (draft) => {
        draft.updateCompanyTitleLoading = false;
        draft.loginLoading = false;
        // Maybe store error
      });
    }

    case LOGOUT: {
      return produce(state, (draft) => {
        draft.user = null;
        draft.userWasShownErrors = initialState.userWasShownErrors;
        draft.updateCompanyTitleLoading = false;
      });
    }

    case SILENT_LOGIN: {
      const {
        user,
        azAccountName,
        databaseBeingUsed,
        signInErrors = initialState.signInErrors,
      } = payload;

      return produce(state, (draft) => {
        draft.user = user;
        draft.azAccountName = azAccountName;
        draft.databaseBeingUsed = databaseBeingUsed;
        draft.signInErrors = signInErrors;
        draft.updateCompanyTitleLoading = false;
      });
    }

    case UPDATE_PROFILE: {
      const { user } = payload;

      return produce(state, (draft) => {
        draft.user = user;
      });
    }

    case GET_ACCOUNT_INFO: {
      const {
        user,
        azAccountName,
        databaseBeingUsed,
      } = payload;

      return produce(state, (draft) => {
        draft.user = user;
        draft.azAccountName = azAccountName;
        draft.databaseBeingUsed = databaseBeingUsed;
      });
    }

    case GET_USERS_LIST: {
      return produce(state, (draft) => {
        draft.usersList.loading = true;
        draft.usersList.users = initialState.usersList.users;
      });
    }

    case GET_USERS_LIST_SUCCESS: {
      return produce(state, (draft) => {
        draft.usersList.loading = false;
        draft.usersList.users = payload;
      });
    }

    case GET_USERS_LIST_ERROR: {
      return produce(state, (draft) => {
        draft.usersList.loading = false;
        draft.usersList.users = initialState.usersList.users;
      });
    }

    case SIGN_IN_AS_USER: {
      return produce(state, (draft) => {
        draft.usersList.loading = true;
      });
    }

    case SIGN_IN_AS_USER_ERROR: {
      return produce(state, (draft) => {
        draft.usersList.loading = false;
        draft.updateCompanyTitleLoading = false;
      });
    }

    case SIGN_IN_AS_USER_SUCCESS: {
      const {
        user,
        azAccountName,
        databaseBeingUsed,
        signInErrors = initialState.signInErrors,
      } = payload;
      return produce(state, (draft) => {
        draft.usersList.loading = false;
        draft.user = user;
        draft.azAccountName = azAccountName;
        draft.databaseBeingUsed = databaseBeingUsed;
        draft.signInErrors = signInErrors;
        draft.userWasShownErrors = initialState.userWasShownErrors;
        draft.updateCompanyTitleLoading = false;
      });
    }

    case CHANGE_COMPANY_TITLE: {
      return produce(state, (draft) => {
        draft.updateCompanyTitleLoading = true;
      });
    }

    case CHANGE_COMPANY_TITLE_ERROR: {
      return produce(state, (draft) => {
        draft.updateCompanyTitleLoading = false;
      });
    }

    case CHANGE_COMPANY_TITLE_SUCCESS: {
      return produce(state, (draft) => {
        draft.updateUsersTitle = false;
        draft.updateCompanyTitleLoading = false;
      });
    }

    case CHANGE_CAMPAIGNS: {
      return produce(state, (draft) => {
        draft.updateCampaignLoading = true;
      });
    }

    case CHANGE_CAMPAIGNS_ERROR: {
      return produce(state, (draft) => {
        draft.updateCampaignLoading = false;
      });
    }

    case CHANGE_CAMPAIGNS_SUCCESS: {
      return produce(state, (draft) => {
        draft.updateCampaignLoading = false;
        draft.campaignNamesToChange = [];
      });
    }

    case SET_USER_WAS_SHOWN_ERRORS: {
      return produce(state, (draft) => {
        draft.userWasShownErrors = payload ? [
          ...state.userWasShownErrors,
          ...payload,
        ] : initialState.userWasShownErrors;
      });
    }

    /* ******************
     * here we are listening accountSettingsActions
    ******************* */
    case APPLY_REFERRAL_CODE_SUCCESS:
    case CREATE_REFERRAL_CODE_SUCCESS:
    case GET_USER_SUBSCRIPTION_SUCCESS:
    case SUBSCRIBE_SUCCESS:
    case UNSUBSCRIBE_SUCCESS:
    case CANCEL_SUBSCRIPTION_AT_PERIOD_END_SUCCESS:
    case RETRIEVE_USER_SUBSCRIPTION:
    case UPDATE_SUBSCRIPTION_PAYMENT_METHOD_SUCCESS: {
      return produce(state, (draft) => {
        draft.user.userSubscription = payload ? {
          ...state?.user?.userSubscription || {},
          ...payload,
        } : state?.user?.userSubscription || {};
      });
    }
    /* END */

    default: {
      return state;
    }
  }
};

export default accountReducer;
