/* eslint-disable no-param-reassign */
import produce from 'immer';
import {
  GET_FORM_LINE_DEFINITION,
  GET_FORM_LINE_DEFINITION_SUCCESS,
  GET_FORM_LINE_DEFINITION_ERROR,
  DELETE_FORM_LINE_DEFINITION,
  DELETE_FORM_LINE_DEFINITION_SUCCESS,
  DELETE_FORM_LINE_DEFINITION_ERROR,
  CLEAR_FORM_LINE_DEFINITION_WAS_DELETED,
  UPDATE_FORM_LINE_DEFINITION,
  UPDATE_FORM_LINE_DEFINITION_SUCCESS,
  UPDATE_FORM_LINE_DEFINITION_ERROR,
  CREATE_FORM_LINE_DEFINITION,
  CREATE_FORM_LINE_DEFINITION_SUCCESS,
  CREATE_FORM_LINE_DEFINITION_ERROR,
  SET_FORM_LINE_DEFINITION_DETAILS_TAB,
  SET_FORM_FORM_LINE_DEFINITION,
  SET_VALUES_FORM_LINE_IMAGE,
  SET_FORM_LINE_DEFINITION_ID,
  SET_UPDATE_PARTY_FORM_LINE,
} from 'src/actions/formDefinitionDetailsActions';

const formLineDefinitionImage = {
  image: false,
  imageContainer: null,
  loading: false,
};

const initialState = {
  formDefinitionId: null,
  formDefinition: {},
  formDefinitionWasDeleted: false,
  loading: false,
  currentNavigationTab: 'formLine',
  formLineDefinitionImage,
  updatePartyFormLine: false,
};

const formDefinitionDetailsReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_FORM_LINE_DEFINITION: {
      return produce(state, (draft) => {
        draft.loading = true;
      });
    }

    case GET_FORM_LINE_DEFINITION_SUCCESS: {
      return produce(state, (draft) => {
        draft.formDefinition = payload?.formDefinition || {};
        draft.loading = false;
      });
    }

    case GET_FORM_LINE_DEFINITION_ERROR: {
      return produce(state, (draft) => {
        draft.loading = false;
      });
    }

    case DELETE_FORM_LINE_DEFINITION: {
      return produce(state, (draft) => {
        draft.loading = true;
      });
    }

    case DELETE_FORM_LINE_DEFINITION_SUCCESS: {
      return produce(state, (draft) => {
        draft.formDefinition = payload ? initialState.formDefinition : state.formDefinition;
        draft.loading = false;
        draft.formDefinitionWasDeleted = true;
      });
    }

    case DELETE_FORM_LINE_DEFINITION_ERROR: {
      return produce(state, (draft) => {
        draft.loading = false;
      });
    }

    case CLEAR_FORM_LINE_DEFINITION_WAS_DELETED: {
      return produce(state, (draft) => {
        draft.formDefinitionWasDeleted = false;
      });
    }

    case CREATE_FORM_LINE_DEFINITION:
    case UPDATE_FORM_LINE_DEFINITION: {
      return produce(state, (draft) => {
        draft.loading = true;
      });
    }

    case CREATE_FORM_LINE_DEFINITION_SUCCESS:
    case CREATE_FORM_LINE_DEFINITION_ERROR:
    case UPDATE_FORM_LINE_DEFINITION_SUCCESS:
    case UPDATE_FORM_LINE_DEFINITION_ERROR: {
      return produce(state, (draft) => {
        draft.loading = false;
      });
    }

    case SET_FORM_LINE_DEFINITION_DETAILS_TAB: {
      return produce(state, (draft) => {
        draft.currentNavigationTab = payload || initialState.currentNavigationTab;
      });
    }

    case SET_FORM_FORM_LINE_DEFINITION: {
      return produce(state, (draft) => {
        draft.formDefinition = payload ? {
          ...state.formDefinition,
          ...payload,
        } : initialState.formDefinition;
      });
    }

    case SET_VALUES_FORM_LINE_IMAGE: {
      return produce(state, (draft) => {
        draft.formLineDefinitionImage = payload ? {
          ...state.formLineDefinitionImage,
          ...payload,
        } : formLineDefinitionImage;
      });
    }

    case SET_FORM_LINE_DEFINITION_ID: {
      return produce(state, (draft) => {
        draft.formDefinitionId = payload || null;
      });
    }

    case SET_UPDATE_PARTY_FORM_LINE: {
      return produce(state, (draft) => {
        draft.updatePartyFormLine = payload;
      });
    }

    default: {
      return state;
    }
  }
};

export default formDefinitionDetailsReducer;
