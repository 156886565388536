import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { withStyles } from 'tss-react/mui';
import ModalDialog from 'src/components/ModalDialog/index';
import TOSAndPrivacyStatementText from 'src/containers/TOSAndPrivacyStatement/TOSAndPrivacyStatementText';
import { agreeToTermsOfService } from 'src/actions/accountActions';
import helpers from 'src/helpers';

import tosAndPrivacyStatementTextStyle from 'src/containers/TOSAndPrivacyStatement/tosAndPrivacyStatementText.style';

function TOSAndPrivacyStatement({ classes, logout }) {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.account);
  const { agreedToPS, agreedToTOS } = user || {};

  const isAdmin = helpers.isUserAdmin(user);

  const handleCloseModal = () => {
    logout();
  };

  const handleClickIAgree = () => {
    dispatch(agreeToTermsOfService());
  };

  if ((user && agreedToPS && agreedToTOS) || (isAdmin && agreedToPS === undefined && agreedToTOS === undefined)) {
    return null;
  }

  return (
    <ModalDialog
      title="Terms of Service and Privacy Policy"
      onClose={handleCloseModal}
      onOk={handleClickIAgree}
      content={<TOSAndPrivacyStatementText classes={classes} />}
      closeText="Log Out"
      okText="Agree"
      isVisible
      closeOnClickOnlyTheButton
    />
  );
}

TOSAndPrivacyStatement.propTypes = {
  classes: PropTypes.object.isRequired,
  logout: PropTypes.func.isRequired,
};

export default withStyles(TOSAndPrivacyStatement, tosAndPrivacyStatementTextStyle);
