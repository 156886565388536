/* eslint-disable react/jsx-no-target-blank */
import React from 'react';
import H4Title from 'src/components/HTitleTags/H4Title';
import VimeoVideo from 'src/components/VimeoVideo/index';
import GoogleVideo from 'src/components/GoogleVideo/index';
import GoogleDoc from 'src/components/GoogleDoc/index';

const text = {
  cardNumber1: (
    <>
      <h2>
        1. Your Account Setup
      </h2>
      <H4Title>
        Setting Up Your Account
      </H4Title>
      <h4>
        Your Info
      </h4>
      <VimeoVideo
        link="https://player.vimeo.com/video/788147718"
        positionsY="left"
      />
      <h4>
        <br />
        Creating & Assigning a Customer Tag
      </h4>
      <GoogleVideo
        link="https://drive.google.com/file/d/1qM7Rs18KidBVJivJRIf5BBLQ4lqTfcPT"
        positionsY="left"
      />
    </>
  ),

  cardNumber2: (
    <>
      <h2>
        2. Order Follow-Up
      </h2>
      <H4Title>
        Following Up With Your Orders - Fast, Easy, & You Are In Full Control!
      </H4Title>
      <h4>
        Overview Video
      </h4>
      <VimeoVideo
        link="https://player.vimeo.com/video/788166654"
        positionsY="left"
      />
      <h4>
        Order Follow-Up Edit Video
      </h4>
      <VimeoVideo
        link="https://player.vimeo.com/video/788166540"
        positionsY="left"
      />
    </>
  ),

  cardNumber3: (
    <>
      <h2>
        3. Message Templates
      </h2>
      <H4Title>
        Customizing Order Follow-Up Templates
      </H4Title>
      <h4>
        Overview Video
      </h4>
      <VimeoVideo
        link="https://player.vimeo.com/video/788166811"
        positionsY="left"
      />
      <p>
        <br />
        <a
          target="_blank"
          href="https://docs.google.com/document/d/1wEEb_-Gxk84kMZrKephi20nduGMJeaS3gqtPbbh7nPo"
        >
          Customizing Message Templates Guide
        </a>
        &nbsp;Click on the link to open in a different tab, or scroll through below.
      </p>
      <GoogleDoc
        link="https://docs.google.com/document/d/e/2PACX-1vR6y3KMTTctPodYMUb5CisUmPY2zqLx3rGl1RqadvHPUWn5BkYTHeUckixMtjWKZOlI-8X8YAJWi6H7/pub?embedded=true"
      />
    </>
  ),

  cardNumber4: (
    <>
      <h2>
        4. Parties & Events Module
      </h2>
      <H4Title>
        Setting up the Party Module
      </H4Title>
      <h4>
        Setup Video
      </h4>
      <VimeoVideo
        link="https://player.vimeo.com/video/788166112"
        positionsY="left"
      />
      <H4Title>
        Using the Party Module
      </H4Title>
      <h4>
        How-To Use Video
      </h4>
      <VimeoVideo
        link="https://player.vimeo.com/video/788166039"
        positionsY="left"
      />
    </>
  ),

  cardNumber5: (
    <>
      <h2>
        5. Smart Actions
      </h2>
      <H4Title>
        Setting up Smart Actions
      </H4Title>
      <h4>
        Setup Video
      </h4>
      <VimeoVideo
        link="https://player.vimeo.com/video/799730827"
        positionsY="left"
      />
      <H4Title>
        <br />
        Using Smart Actions in Parties
      </H4Title>
      <h4>
        How-To Use Video
      </h4>
      <VimeoVideo
        link="https://player.vimeo.com/video/799734218"
        positionsY="left"
      />
    </>
  ),

  cardNumber6: (
    <>
      <h2>
        6. Party Invite/Join Form
      </h2>
      <H4Title>
        Setting up & Using
      </H4Title>
      <h4>
        Setup & Using Video
      </h4>
      <VimeoVideo
        link="https://player.vimeo.com/video/835249193"
        positionsY="left"
      />
    </>
  ),

  cardNumber7: (
    <>
      <h2>
        7. Contacts
      </h2>
      <H4Title>
        Contact Screen - Managing & Actions for your Contacts!
      </H4Title>
      <h4>
        Overview Video
      </h4>
      <GoogleVideo
        link="https://drive.google.com/file/d/1x-Wfye_v2tSqKWCbS_90hpM16OW5GrGX"
        positionsY="left"
      />
      <h4>
        Filter By - Selecting Contacts
      </h4>
      <GoogleVideo
        link="https://drive.google.com/file/d/1xOqAJ5CU4V081-vadsJ9Mb76B1iml3E5"
        positionsY="left"
      />
      <h4>
        Sending Texts and Adding Tag & Campaign
      </h4>
      <GoogleVideo
        link="https://drive.google.com/file/d/1J7dgYOoRySQfCbPYhon7qDnKsLuW92wP"
        positionsY="left"
      />
      <h4>
        Birthdays - Finding & Using
      </h4>
      <GoogleVideo
        link="https://drive.google.com/file/d/1qcJXr0mf8kqUVCxYvY-rWV9c0WfnBA7G"
        positionsY="left"
      />
    </>
  ),

};

export default text;
