/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import helpers from 'src/helpers';

function PerfectScrollbarTable({
  children,
  subDefaultHeight,
}) {
  const [scrollHeight, setScrollHeight] = useState('none');

  const getScrollMaxHeightOnWindow = () => {
    const heightW = helpers.transform.toInt10(document.documentElement.clientHeight);
    const heightBeforeScrollBox = helpers.transform.toInt10(document.getElementById('scrollBox')?.offsetTop || '0');
    const heightAfterScrollBox = helpers.transform.toInt10(
      document.getElementById('tablePagination')?.offsetHeight || '0'
    );
    const heightHeaderTopRight = helpers.transform.toInt10(
      document.getElementById('header-top-right')?.offsetHeight || '0'
    );
    const scrollBoxHeight = heightW - heightBeforeScrollBox - heightAfterScrollBox - heightHeaderTopRight - subDefaultHeight;
    return scrollBoxHeight < 300 ? 300 : scrollBoxHeight;
  };

  useEffect(() => {
    const updateScrollHeight = () => {
      setScrollHeight(getScrollMaxHeightOnWindow());
    };
    updateScrollHeight();
    window.addEventListener('resize', updateScrollHeight);
    return () => window.removeEventListener('resize', updateScrollHeight);
  }, []);

  return (
    <PerfectScrollbar
      id="scrollBox"
      style={{ maxHeight: scrollHeight }}
    >
      {children}
    </PerfectScrollbar>
  );
}

PerfectScrollbarTable.propTypes = {
  children: PropTypes.any.isRequired,
  subDefaultHeight: PropTypes.number,
};

PerfectScrollbarTable.defaultProps = {
  subDefaultHeight: 0,
};

export default PerfectScrollbarTable;
