/* eslint-disable no-param-reassign */
import produce from 'immer';
import {
  GET_FORM,
  GET_FORM_SUCCESS,
  GET_FORM_ERROR,
  MOVE_FORM_LINE,
  MOVE_FORM_LINE_SUCCESS,
  MOVE_FORM_LINE_ERROR,
  SET_BACK_TO_FORM_LINES_LIST,
  SET_FORM_PARTY,
  SET_FORM_LINES,
} from 'src/actions/formPartyJoinActions';

const backToFormList = {
  formId: null,
  formLines: [],
  active: false,
  comeBack: false,
};

const initialState = {
  partyId: null,
  form: {},
  formId: null,
  formLines: [],
  loading: false,
  backToFormList,
};

const formPartyJoinReducer = (state = initialState, form) => {
  const { type, payload } = form;
  switch (type) {
    case GET_FORM: {
      return produce(state, (draft) => {
        draft.loading = true;
      });
    }

    case GET_FORM_SUCCESS: {
      return produce(state, (draft) => {
        draft.form = payload?.form || {};
        draft.formId = payload?.formId || null;
        draft.formLines = payload?.formLines || [];
        draft.loading = false;
      });
    }

    case GET_FORM_ERROR: {
      return produce(state, (draft) => {
        draft.loading = false;
      });
    }

    case MOVE_FORM_LINE: {
      return produce(state, (draft) => {
        draft.loading = true;
      });
    }

    case MOVE_FORM_LINE_SUCCESS: {
      return produce(state, (draft) => {
        draft.loading = false;
      });
    }

    case MOVE_FORM_LINE_ERROR: {
      return produce(state, (draft) => {
        draft.loading = false;
      });
    }

    case SET_BACK_TO_FORM_LINES_LIST: {
      return produce(state, (draft) => {
        draft.backToFormList = payload ? {
          ...state.backToFormList,
          ...payload,
        } : backToFormList;
      });
    }

    case SET_FORM_PARTY: {
      return produce(state, (draft) => {
        draft.partyId = payload || null;
        draft.formId = null;
        draft.form = {};
        draft.formLines = [];
        draft.loading = false;
      });
    }

    case SET_FORM_LINES: {
      return produce(state, (draft) => {
        draft.formLines = payload || [];
      });
    }

    default: {
      return state;
    }
  }
};

export default formPartyJoinReducer;
