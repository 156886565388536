import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { withStyles } from 'tss-react/mui';
import Breadcrumbs from 'src/components/View/Header/Breadcrumbs';
import SearchLine from 'src/components/View/Header/SearchLine';

import headerStyle from 'src/components/View/Header/header.style';

function Header({
  classes,
  title,
  message,
  breadcrumbs,
  searchBox,
  buttonBox,
  topButtonBox,
  leftTitleButton,
}) {
  return (
    <Grid
      id="header-top-right"
      container
      spacing={3}
      justifyContent="space-between"
      className={classes.root}
    >
      {topButtonBox && (
      <Grid
        xs={12}
      >
        {topButtonBox}
      </Grid>
      )}
      <Grid>
        <Typography
          variant="h3"
          color="textPrimary"
        >
          {leftTitleButton && (
          <div className={classes.leftTitleButton}>
            {leftTitleButton}
          </div>
          )}
          {title}
        </Typography>
        <Breadcrumbs breadcrumbs={breadcrumbs} />
        {message}
        {searchBox && (
        <SearchLine>
          {searchBox}
        </SearchLine>
        )}
      </Grid>
      {buttonBox && (
      <Grid>
        <div className={classes.buttonBox}>
          {buttonBox}
        </div>
      </Grid>
      )}
    </Grid>
  );
}

Header.propTypes = {
  classes: PropTypes.object.isRequired,
  title: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
  ]).isRequired,
  breadcrumbs: PropTypes.array,
  message: PropTypes.node,
  searchBox: PropTypes.node,
  buttonBox: PropTypes.node,
  topButtonBox: PropTypes.node,
  leftTitleButton: PropTypes.node,
};
Header.defaultProps = {
  breadcrumbs: [],
  message: null,
  searchBox: null,
  buttonBox: null,
  topButtonBox: null,
  leftTitleButton: null,
};

export default withStyles(Header, headerStyle, { withTheme: true });
