import axios from 'src/utils/axios';
import { NOTIFICATION_STATUS } from 'src/constants';
import { addNotification } from 'src/actions/notificationActions';

export const SET_FORM_DEFINITION = '@formdefinition/set-form-definition';
export const GET_FORM_DEFINITION = '@formdefinition/get-form-definition';
export const GET_FORM_DEFINITION_SUCCESS = '@formdefinition/get-form-definition-success';
export const GET_FORM_DEFINITION_ERROR = '@formdefinition/get-form-definition-error';

export const MOVE_FORM_DEFINITION_LINE = '@formdefinition/move-form-definition-line';
export const MOVE_FORM_DEFINITION_LINE_SUCCESS = '@formdefinition/move-form-definition-line-success';
export const MOVE_FORM_DEFINITION_LINE_ERROR = '@formdefinition/move-form-definition-line-error';

export const SET_SELECTED_FORM_DEFINITION = '@formdefinition/set-selected-form-definition';

export const SET_BACK_TO_FORM_LINES_DEFINITION_LIST = '@formdefinition/set-back-to-form-lines-definition-list';

export const SET_FORM_DEFINITION_PARTY_TYPE = '@formdefinition/set-form-definition-party-type';

export const SET_VALUES_AUTO_MOVE_FORM_ROWS = '@formdefinition/set-values-auto-move-form-rows';

export const SET_FORM_LINES_DEFINITION = '@formdefinition/set-form-lines-definition';

export const getFormDefinition = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: GET_FORM_DEFINITION,
    });
    const { formDefinition } = getState();
    const {
      partyTypeId,
      formDefinitionId,
    } = formDefinition || {};
    const params = {
      partyTypeId,
      formDefinitionId,
    };
    const response = await axios.post('/api/formDefinition', { params });
    dispatch({
      type: GET_FORM_DEFINITION_SUCCESS,
      payload: response?.data || {}
    });
  } catch (e) {
    const {
      message,
      error,
    } = e?.response?.data || {};
    const { message: errorMessage } = error || {};
    dispatch(addNotification(GET_FORM_DEFINITION_ERROR, message || errorMessage || 'Try again later', NOTIFICATION_STATUS.ERROR));
    dispatch({
      type: GET_FORM_DEFINITION_ERROR,
    });
  }
};

export const moveFormDefinitionLineUpDown = (formDefinitionId, formLineDefinitionId, upDown) => async (dispatch, getState) => {
  try {
    dispatch({
      type: MOVE_FORM_DEFINITION_LINE,
    });
    const {
      autoMoveFormRows,
    } = getState()?.formDefinition || {};
    const params = {
      formDefinitionId,
      formLineDefinitionId,
      upDown,
      autoMoveFormRows,
    };
    const response = await axios.post('/api/formDefinition/moveLineUpDown', { params });

    const {
      success,
    } = response?.data || {};

    if (!success) {
      dispatch(addNotification(MOVE_FORM_DEFINITION_LINE_ERROR, 'Can not move form line', NOTIFICATION_STATUS.ERROR));
      dispatch({
        type: MOVE_FORM_DEFINITION_LINE_ERROR,
      });
      return;
    }

    dispatch({
      type: MOVE_FORM_DEFINITION_LINE_SUCCESS,
      payload: response?.data || {}
    });
    await dispatch(getFormDefinition());
  } catch (e) {
    const {
      message,
      error,
    } = e?.response?.data || {};
    const { message: errorMessage } = error || {};
    dispatch(addNotification(MOVE_FORM_DEFINITION_LINE_ERROR, message || errorMessage || 'Try again later', NOTIFICATION_STATUS.ERROR));
    dispatch({
      type: MOVE_FORM_DEFINITION_LINE_ERROR,
    });
  }
};

export const setFormDefinition = (formDefinition) => ({
  type: SET_FORM_DEFINITION,
  payload: formDefinition
});

export const setBackToFormLinesDefinitionList = (payload) => ({
  type: SET_BACK_TO_FORM_LINES_DEFINITION_LIST,
  payload,
});

export const clearBackToFormLinesDefinitionList = (clearAll = false) => ({
  type: SET_BACK_TO_FORM_LINES_DEFINITION_LIST,
  payload: !clearAll ? {
    formDefinition: [],
  } : false,
});

export const setFormDefinitionPartyType = (payload) => ({
  type: SET_FORM_DEFINITION_PARTY_TYPE,
  payload,
});

export const setFormLinesDefinition = (payload) => ({
  type: SET_FORM_LINES_DEFINITION,
  payload,
});

export const setAutoMoveFormRows = (values) => ({
  type: SET_VALUES_AUTO_MOVE_FORM_ROWS,
  payload: values
});
