/* eslint-disable no-param-reassign */
import produce from 'immer';
import {
  GET_SMART_ACTIONS,
  GET_SMART_ACTIONS_SUCCESS,
  GET_SMART_ACTIONS_ERROR,
  SET_SMART_ACTIONS_QUERY,
  SET_SELECTED_SMART_ACTIONS,
  SET_BACK_TO_SMART_ACTION_LIST,
  SET_SMART_ACTIONS_PARTY_TYPE,
  SET_VISIBLE_MODAL_FORM_NUMBER,
  SET_VALUES_FORM_APPLY_SHARE_CODE,
  VERIFY_CODE,
  VERIFY_CODE_SUCCESS,
  VERIFY_CODE_ERROR,
  APPLY_SHARE_CODE,
  APPLY_SHARE_CODE_SUCCESS,
  APPLY_SHARE_CODE_ERROR,
} from 'src/actions/smartActionsActions';

const query = {
  search: '',
  status: 'all',
  sort: [['code', 'asc']],
  count: 0,
};

const backToSmartActionList = {
  smartActionGroupId: null,
  smartActions: [],
  query: {},
  active: false,
  comeBack: false,
};

const formApplyShareCode = {
  loading: false,
  shareCode: '',
  validCode: true,
  smartActionOwnerFirstName: '',
  smartActionOwnerLastName: '',
  smartActionName: '',
};

const initialState = {
  partyTypeId: null,
  smartActionGroup: {},
  smartActionGroupId: null,
  smartActionGroupShareCode: null,
  smartActions: [],
  selectedSmartActions: [],
  loading: false,
  query,
  backToSmartActionList,
  visibleModalFormNumber: null,
  formApplyShareCode,
};

const smartActionsReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_SMART_ACTIONS: {
      return produce(state, (draft) => {
        draft.loading = true;
      });
    }

    case GET_SMART_ACTIONS_SUCCESS: {
      return produce(state, (draft) => {
        draft.smartActions = payload?.smartActions || [];
        draft.smartActionGroupId = payload?.actionGroupId || null;
        draft.smartActionGroupShareCode = payload?.actionGroupShareCode || null;
        draft.query.count = payload?.count || 0;
        draft.loading = false;
        draft.selectedSmartActions = [];
      });
    }

    case GET_SMART_ACTIONS_ERROR: {
      return produce(state, (draft) => {
        draft.loading = false;
      });
    }

    case VERIFY_CODE: {
      return produce(state, (draft) => {
        draft.formApplyShareCode.loading = true;
      });
    }

    case VERIFY_CODE_SUCCESS: {
      return produce(state, (draft) => {
        draft.formApplyShareCode.validCode = true;
        draft.formApplyShareCode.loading = false;
        draft.formApplyShareCode.smartActionOwnerFirstName = payload?.smartActionOwnerFirstName || [];
        draft.formApplyShareCode.smartActionOwnerLastName = payload?.smartActionOwnerLastName || [];
        draft.formApplyShareCode.smartActionName = payload?.smartActionName || [];
      });
    }

    case VERIFY_CODE_ERROR: {
      return produce(state, (draft) => {
        draft.formApplyShareCode.loading = false;
        draft.formApplyShareCode.validCode = false;
      });
    }

    case SET_SMART_ACTIONS_QUERY: {
      return produce(state, (draft) => {
        draft.query = payload ? {
          ...state.query,
          ...payload
        } : initialState.query;
      });
    }

    case SET_SELECTED_SMART_ACTIONS: {
      return produce(state, (draft) => {
        draft.selectedSmartActions = payload || [];
      });
    }

    case SET_BACK_TO_SMART_ACTION_LIST: {
      return produce(state, (draft) => {
        draft.backToSmartActionList = payload ? {
          ...state.backToSmartActionList,
          ...payload,
        } : backToSmartActionList;
      });
    }

    case SET_SMART_ACTIONS_PARTY_TYPE: {
      return produce(state, (draft) => {
        draft.partyTypeId = payload || [];
      });
    }

    case SET_VISIBLE_MODAL_FORM_NUMBER: {
      return produce(state, (draft) => {
        draft.visibleModalFormNumber = payload || null;
      });
    }

    case SET_VALUES_FORM_APPLY_SHARE_CODE: {
      return produce(state, (draft) => {
        draft.formApplyShareCode = payload ? {
          ...state.formApplyShareCode,
          ...payload,
        } : formApplyShareCode;
      });
    }

    case APPLY_SHARE_CODE: {
      return produce(state, (draft) => {
        draft.loading = true;
      });
    }

    case APPLY_SHARE_CODE_SUCCESS: {
      return produce(state, (draft) => {
        draft.loading = false;
      });
    }

    case APPLY_SHARE_CODE_ERROR: {
      return produce(state, (draft) => {
        draft.loading = false;
      });
    }

    default: {
      return state;
    }
  }
};

export default smartActionsReducer;
