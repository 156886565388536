/* eslint-disable no-param-reassign */
import produce from 'immer';
import {
  GET_FORM_DEFINITION,
  GET_FORM_DEFINITION_SUCCESS,
  GET_FORM_DEFINITION_ERROR,
  MOVE_FORM_DEFINITION_LINE,
  MOVE_FORM_DEFINITION_LINE_SUCCESS,
  MOVE_FORM_DEFINITION_LINE_ERROR,
  SET_BACK_TO_FORM_LINES_DEFINITION_LIST,
  SET_FORM_DEFINITION_PARTY_TYPE,
  SET_VALUES_AUTO_MOVE_FORM_ROWS,
  SET_FORM_LINES_DEFINITION,
} from 'src/actions/formDefinitionActions';

const backToFormDefinitionList = {
  formDefinitionId: null,
  formLinesDefinition: [],
  active: false,
  comeBack: false,
};

const initialState = {
  partyTypeId: null,
  formDefinition: {},
  formDefinitionId: null,
  formLinesDefinition: [],
  loading: false,
  backToFormDefinitionList,
  autoMoveFormRows: true,
};

const formDefinitionReducer = (state = initialState, formDefinition) => {
  const { type, payload } = formDefinition;
  switch (type) {
    case GET_FORM_DEFINITION: {
      return produce(state, (draft) => {
        draft.loading = true;
      });
    }

    case GET_FORM_DEFINITION_SUCCESS: {
      return produce(state, (draft) => {
        draft.formDefinition = payload?.formDefinition || {};
        draft.formDefinitionId = payload?.formDefinitionId || null;
        draft.formLinesDefinition = payload?.formLinesDefinition || [];
        draft.loading = false;
      });
    }

    case GET_FORM_DEFINITION_ERROR: {
      return produce(state, (draft) => {
        draft.loading = false;
      });
    }

    case MOVE_FORM_DEFINITION_LINE: {
      return produce(state, (draft) => {
        draft.loading = true;
      });
    }

    case MOVE_FORM_DEFINITION_LINE_SUCCESS: {
      return produce(state, (draft) => {
        draft.loading = false;
      });
    }

    case MOVE_FORM_DEFINITION_LINE_ERROR: {
      return produce(state, (draft) => {
        draft.loading = false;
      });
    }

    case SET_BACK_TO_FORM_LINES_DEFINITION_LIST: {
      return produce(state, (draft) => {
        draft.backToFormDefinitionList = payload ? {
          ...state.backToFormDefinitionList,
          ...payload,
        } : backToFormDefinitionList;
      });
    }

    case SET_FORM_DEFINITION_PARTY_TYPE: {
      return produce(state, (draft) => {
        draft.partyTypeId = payload || null;
        draft.formDefinitionId = null;
        draft.formDefinition = {};
        draft.formLinesDefinition = [];
        draft.loading = false;
      });
    }

    case SET_VALUES_AUTO_MOVE_FORM_ROWS: {
      return produce(state, (draft) => {
        draft.autoMoveFormRows = payload;
      });
    }

    case SET_FORM_LINES_DEFINITION: {
      return produce(state, (draft) => {
        draft.formLinesDefinition = payload || [];
      });
    }

    default: {
      return state;
    }
  }
};

export default formDefinitionReducer;
